import {
  IconBrandHipchat,
  IconClipboardList,
  IconDatabase,
  IconShare,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ChatbotServices from "../apis/chatbot/ChatbotServices";
import ProjectServices from "../apis/project/ProjectServices";
import SitemapServices from "../apis/sitemap/SitemapServices";
import "../assets/css/demo.css";
import "../assets/vendor/css/rtl/core.css";
import "../assets/vendor/css/rtl/theme-default.css";
import { ErrorToaster, SuccessToaster } from "../components/Toaster";
import Chattab from "../components/chatTab";
import Datatab from "../components/dataTab";
import SharingTab from "../components/sharingTab";
import { useAuth } from "../context/UseContext";
import Wrapper from "../wrapper/wrapper";

export default function SettingNew() {

  const [check, setCheck] = useState(1);

  const handleKey = (val) => {
    setCheck(val);
  };

  const { register, handleSubmit, setValue } = useForm();

  const [loading, setLoading] = useState(false);

  const { user } = useAuth();

  const getProjectName = async () => {
    try {
      let params = {
        projectId: user?.projectId,
      };
      const { data } = await ProjectServices.getProjectName(params);
      setValue("projectName", data?.project_name);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const updateProjectName = async (formData) => {
    setLoading(true)
    try {
      let obj = {
        projectId: user?.projectId,
        projectName: formData?.projectName,
      };
      const { data } = await ProjectServices.updateProjectName(obj);
      SuccessToaster("Project name update successfully");
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    if (user?.configuredByAdmin) {
      getProjectName();
    }
  }, []);

  return (
    <Wrapper>
      <div className="layout-page">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <h2 className="py-3 mb-4 fw-bold">
              <span className="text-muted ">Project Settings /</span>{" "}
              {check === 1 && "Project"}
              {check === 2 && "Data"}
              {check === 3 && "Chatbot"}
              {check === 4 && "Sharing"}
            </h2>

            <div className="row">
              <div className="col-md-12">
                <ul className="nav nav-pills flex-column flex-md-row mb-4">
                  <li className="nav-item " onClick={() => handleKey(1)}>
                    <a
                      className={`nav-link ${check === 1 && "active"}`}
                      style={{ cursor: "pointer" }}
                    >
                      <IconClipboardList className="me-1" /> Project
                    </a>
                  </li>
                  <li className="nav-item" onClick={() => handleKey(2)}>
                    <a
                      className={`nav-link ${check === 2 && "active"}`}
                      style={{ cursor: "pointer" }}
                    >
                      <IconDatabase className="me-1" /> Data
                    </a>
                  </li>
                  <li className="nav-item" onClick={() => handleKey(3)}>
                    <a
                      className={`nav-link ${check === 3 && "active"}`}
                      style={{ cursor: "pointer" }}
                    >
                      <IconBrandHipchat className="me-1" />
                      Chatbot
                    </a>
                  </li>
                  <li className="nav-item" onClick={() => handleKey(4)}>
                    <a
                      className={`nav-link ${check === 4 && "active"}`}
                      style={{ cursor: "pointer" }}
                    >
                      <IconShare className="me-1" />
                      Sharing
                    </a>
                  </li>
                </ul>

                {check == 1 && (
                  <>
                    <div className="settings-body mt-6 shadow-sm shadow-black rounded bg-white transition">
                      <div className="setting-header border-b p-4">
                        <span className="h3 fw-bold">Project Settings</span>
                      </div>
                      <div className="setting-content">
                        {user?.configuredByAdmin ? (
                          <div className="p-4">
                            <div className="mb-3 col-span-6 sm:col-span-4">
                              <label
                                className="font-medium text-sm"
                                for="project_name"
                              >
                                <span className="text-dark">Project name</span>
                              </label>
                              <input
                                className="border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm mt-1 block w-full form-control shadow-none"
                                id="project_name"
                                placeholder="Enter your project name"
                                {...register("projectName", {
                                  required: "Please enter project name.",
                                })}
                              />
                            </div>
                            <div className="action-result d-flex flex-row flex-wrap gap-2 align-items-center">
                              <button
                                disabled={loading}
                                type="button"
                                className="btn btn-primary"
                                onClick={handleSubmit(updateProjectName)}
                              >
                                Save Changes
                              </button>
                            </div>
                          </div>
                        ) : (
                          <h5 className="p-4 mb-4 fw-bold">Sorry, Chatbot is currently InActive.</h5>
                        )}
                      </div>
                    </div>
                  </>
                )}

                {check == 2 && (
                  <Datatab />
                )}

                {check == 3 &&
                  <Chattab />
                }

                {check == 4 &&
                  <SharingTab />
                }

              </div>
            </div>
          </div>

          <div class="content-backdrop fade"></div>
        </div>
      </div>
    </Wrapper>
  );
}
