import React, { useState } from "react";
import "../assets/vendor/css/rtl/core.css";
import "../assets/vendor/css/rtl/theme-default.css";
import avatar1 from "../assets/img/avatars/15.png";
import { IconCheckbox, IconBriefcase } from "@tabler/icons-react";
import Swal from "sweetalert2";
import { Modal, Button, Form } from "react-bootstrap";

export default function AccountPanel({ onHide }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleSuspendUser = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Suspend user!",
      customClass: {
        confirmButton: "btn btn-danger me-2",
        cancelButton: "btn btn-label-secondary",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          icon: "success",
          title: "Suspended!",
          text: "User has been suspended.",
          customClass: {
            confirmButton: "btn btn-danger",
          },
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: "Cancelled",
          text: "Cancelled Suspension :)",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
    });
  };

  const handleCancelSubscription = () => {
    setShow(false);
    Swal.fire({
      text: "Are you sure you would like to cancel your subscription?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      customClass: {
        confirmButton: "btn btn-danger me-2",
        cancelButton: "btn btn-label-secondary",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          icon: "success",
          title: "Unsubscribed!",
          text: "Your subscription cancelled successfully.",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: "Cancelled",
          text: "Unsubscription Cancelled!!",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
    });
  };

  return (
    <div className="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0">
      <div className="card mb-4">
        <div className="card-body">
          <div className="user-avatar-section">
            <div className="d-flex align-items-center flex-column">
              <img
                className="img-fluid rounded mb-3 pt-1 mt-4"
                src={avatar1}
                height="100"
                width="100"
                alt="User avatar"
              />
              <div className="user-info text-center">
                <h4 className="mb-2">Violet Mendoza</h4>
                <span className="badge bg-label-secondary mt-1">Author</span>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-around flex-wrap mt-3 pt-3 pb-4 border-bottom">
            <div className="d-flex align-items-start me-4 mt-3 gap-2">
              <span className="badge bg-label-primary p-2 rounded">
                <IconCheckbox />
              </span>
              <div>
                <p className="mb-0 fw-medium">1.23k</p>
                <small>Tasks Done</small>
              </div>
            </div>
            <div className="d-flex align-items-start mt-3 gap-2">
              <span className="badge bg-label-primary p-2 rounded">
                <IconBriefcase />
              </span>
              <div>
                <p className="mb-0 fw-medium">568</p>
                <small>Projects Done</small>
              </div>
            </div>
          </div>
          <h5 className="mt-4 small text-uppercase text-muted">Details</h5>
          <div className="info-container">
            <ul className="list-unstyled">
              <li className="mb-2">
                <span className="fw-medium me-1">Username:</span>
                <span>violet.dev</span>
              </li>
              <li className="mb-2 pt-1">
                <span className="fw-medium me-1">Email:</span>
                <span>vafgot@vultukir.org</span>
              </li>
              <li className="mb-2 pt-1">
                <span className="fw-medium me-1">Status:</span>
                <span className="badge bg-label-success">Active</span>
              </li>
              <li className="mb-2 pt-1">
                <span className="fw-medium me-1">Role:</span>
                <span>Author</span>
              </li>
              <li className="mb-2 pt-1">
                <span className="fw-medium me-1">Tax id:</span>
                <span>Tax-8965</span>
              </li>
              <li className="mb-2 pt-1">
                <span className="fw-medium me-1">Contact:</span>
                <span>(123) 456-7890</span>
              </li>
              <li className="mb-2 pt-1">
                <span className="fw-medium me-1">Languages:</span>
                <span>French</span>
              </li>
              <li className="pt-1">
                <span className="fw-medium me-1">Country:</span>
                <span>England</span>
              </li>
            </ul>
            <div className="d-flex justify-content-center">
              <Button
                className="btn btn-primary me-3"
                variant="primary"
                onClick={handleOpenModal}
              >
                Edit
              </Button>

              <Button
                onClick={handleSuspendUser}
                className="btn btn-danger bg-danger border-danger suspend-user"
              >
                Suspended
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="card mb-4">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-start">
            <span className="badge bg-label-primary">Standard</span>
            <div className="d-flex justify-content-center">
              <sup className="h6 pricing-currency mt-3 mb-0 me-1 text-primary fw-normal">
                $
              </sup>
              <h1 className="mb-0 text-primary">99</h1>
              <sub className="h6 pricing-duration mt-auto mb-2 fw-normal text-muted">
                /month
              </sub>
            </div>
          </div>
          <ul className="ps-3 g-2 my-3">
            <li className="mb-2">10 Users</li>
            <li className="mb-2">Up to 10 GB storage</li>
            <li>Basic Support</li>
          </ul>
          <div className="d-flex justify-content-between align-items-center mb-1 fw-medium text-heading">
            <span>Days</span>
            <span>65% Completed</span>
          </div>
          <div className="progress mb-1" style={{ height: "8px" }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "65%" }}
              aria-valuenow="65"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <span>4 days remaining</span>
          <div className="d-grid w-100 mt-4">
            <Button onClick={handleShow}>Upgrade Plan</Button>
          </div>

          <Modal
            show={show}
            onHide={handleClose}
            centered
            className="modal-simple modal-upgrade-plan"
            aria-labelledby="upgradePlanModal"
          >
            <Modal.Body className="p-3 p-md-5">
              <div className="text-center mb-4">
                <h3 className="mb-2">Upgrade Plan</h3>
                <p>Choose the best plan for user.</p>
              </div>
              <Form id="upgradePlanForm" className="row g-3">
                <Form.Group className="col-sm-8">
                  <Form.Label>Choose Plan</Form.Label>
                  <Form.Control
                    as="select"
                    className="form-select"
                    aria-label="Choose Plan"
                  >
                    <option>Choose Plan</option>
                    <option value="standard">Standard - $99/month</option>
                    <option value="exclusive">Exclusive - $249/month</option>
                    <option value="Enterprise">Enterprise - $499/month</option>
                  </Form.Control>
                </Form.Group>
                <div className="col-sm-4 d-flex align-items-end">
                  <Button type="submit" className="btn-primary">
                    Upgrade
                  </Button>
                </div>
              </Form>
            </Modal.Body>

            <Modal.Body>
              <hr className="mx-md-n5 mx-n3" />
              <p className="mb-0">User current plan is standard plan</p>
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div className="d-flex justify-content-center me-2">
                  <sup className="h6 pricing-currency pt-1 mt-3 mb-0 me-1 text-primary">
                    $
                  </sup>
                  <h1 className="display-5 mb-0 text-primary">99</h1>
                  <sub className="h5 pricing-duration mt-auto mb-2 text-muted">
                    /month
                  </sub>
                </div>
                <Button
                  variant="danger"
                  className="btn-label-danger mt-3"
                  onClick={handleCancelSubscription}
                >
                  Cancel Subscription
                </Button>
              </div>
            </Modal.Body>
          </Modal>

          {/* modal */}
          <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
            <Modal.Header>
              <Button
                onClick={handleCloseModal}
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></Button>
            </Modal.Header>
            <Modal.Body>
              <div className="text-center mb-4">
                <h3 className="mb-2">Edit User Information</h3>
                <p className="text-muted">
                  Updating user details will receive a privacy audit.
                </p>
              </div>

              <Form className="row g-3">
                <div className="col-12 col-md-6">
                  <label className="form-label" for="modalEditUserFirstName">
                    First Name
                  </label>
                  <input
                    type="text"
                    id="modalEditUserFirstName"
                    name="modalEditUserFirstName"
                    className="form-control"
                    placeholder="John"
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label className="form-label" for="modalEditUserLastName">
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="modalEditUserLastName"
                    name="modalEditUserLastName"
                    className="form-control"
                    placeholder="Doe"
                  />
                </div>
                <div className="col-12">
                  <label className="form-label" for="modalEditUserName">
                    Username
                  </label>
                  <input
                    type="text"
                    id="modalEditUserName"
                    name="modalEditUserName"
                    className="form-control"
                    placeholder="john.doe.007"
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label className="form-label" for="modalEditUserEmail">
                    Email
                  </label>
                  <input
                    type="text"
                    id="modalEditUserEmail"
                    name="modalEditUserEmail"
                    className="form-control"
                    placeholder="example@domain.com"
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label className="form-label" for="modalEditUserStatus">
                    Status
                  </label>
                  <select
                    id="modalEditUserStatus"
                    name="modalEditUserStatus"
                    className="select2 form-select"
                    aria-label="Default select example"
                  >
                    <option selected>Status</option>
                    <option value="1">Active</option>
                    <option value="2">Inactive</option>
                    <option value="3">Suspended</option>
                  </select>
                </div>
                <div className="col-12 col-md-6">
                  <label className="form-label" for="modalEditTaxID">
                    Tax ID
                  </label>
                  <input
                    type="text"
                    id="modalEditTaxID"
                    name="modalEditTaxID"
                    className="form-control modal-edit-tax-id"
                    placeholder="123 456 7890"
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label className="form-label" for="modalEditUserPhone">
                    Phone Number
                  </label>
                  <div className="input-group">
                    <span className="input-group-text">US (+1)</span>
                    <input
                      type="text"
                      id="modalEditUserPhone"
                      name="modalEditUserPhone"
                      className="form-control phone-number-mask"
                      placeholder="202 555 0111"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <label className="form-label" for="modalEditUserCountry">
                    Language
                  </label>
                  <select
                    id="modalEditUserCountry"
                    name="modalEditUserCountry"
                    className="select2 form-select"
                    data-allow-clear="true"
                  >
                    <option value="">Select</option>
                    <option value="english">English</option>
                    <option value="spanish">Spanish</option>
                    <option value="french">French</option>
                    <option value="german">German</option>
                    <option value="dutch">Dutch</option>
                    <option value="hebrew">Hebrew</option>
                    <option value="sanskrit">Sanskrit</option>
                    <option value="hindi">Hindi</option>
                  </select>
                </div>

                <div className="col-12 col-md-6">
                  <label className="form-label" for="modalEditUserCountry">
                    Country
                  </label>
                  <select
                    id="modalEditUserCountry"
                    name="modalEditUserCountry"
                    className="select2 form-select"
                    data-allow-clear="true"
                  >
                    <option value="" disabled="disabled">
                      Select
                    </option>
                    <option value="AF">Afghanistan</option>
                    <option value="AX">Åland Islands</option>
                    <option value="AL">Albania</option>
                    <option value="DZ">Algeria</option>
                    <option value="AS">American Samoa</option>
                    <option value="AD">Andorra</option>
                    <option value="AO">Angola</option>
                    <option value="AI">Anguilla</option>
                    <option value="AQ">Antarctica</option>
                    <option value="AG">Antigua and Barbuda</option>
                    <option value="AR">Argentina</option>
                    <option value="AM">Armenia</option>
                    <option value="AW">Aruba</option>
                    <option value="AU">Australia</option>
                    <option value="AT">Austria</option>
                    <option value="AZ">Azerbaijan</option>
                    <option value="BS">Bahamas</option>
                    <option value="BH">Bahrain</option>
                    <option value="BD">Bangladesh</option>
                    <option value="BB">Barbados</option>
                    <option value="BY">Belarus</option>
                    <option value="BE">Belgium</option>
                    <option value="BZ">Belize</option>
                    <option value="BJ">Benin</option>
                    <option value="BM">Bermuda</option>
                    <option value="BT">Bhutan</option>
                    <option value="BO">
                      Bolivia, Plurinational State of
                    </option>
                    <option value="BA">Bosnia and Herzegovina</option>
                    <option value="BW">Botswana</option>
                    <option value="BV">Bouvet Island</option>
                    <option value="BR">Brazil</option>
                    <option value="IO">
                      British Indian Ocean Territory
                    </option>
                    <option value="BN">Brunei Darussalam</option>
                    <option value="BG">Bulgaria</option>
                    <option value="BF">Burkina Faso</option>
                    <option value="BI">Burundi</option>
                    <option value="KH">Cambodia</option>
                    <option value="CM">Cameroon</option>
                    <option value="CA">Canada</option>
                    <option value="CV">Cape Verde</option>
                    <option value="KY">Cayman Islands</option>
                    <option value="CF">Central African Republic</option>
                    <option value="TD">Chad</option>
                    <option value="CL">Chile</option>
                    <option value="CN">China</option>
                    <option value="CX">Christmas Island</option>
                    <option value="CC">Cocos (Keeling) Islands</option>
                    <option value="CO">Colombia</option>
                    <option value="KM">Comoros</option>
                    <option value="CG">Congo</option>
                    <option value="CD">
                      Congo, the Democratic Republic of the
                    </option>
                    <option value="CK">Cook Islands</option>
                    <option value="CR">Costa Rica</option>
                    <option value="CI">Côte d'Ivoire</option>
                    <option value="HR">Croatia</option>
                    <option value="CU">Cuba</option>
                    <option value="CY">Cyprus</option>
                    <option value="CZ">Czech Republic</option>
                    <option value="DK">Denmark</option>
                    <option value="DJ">Djibouti</option>
                    <option value="DM">Dominica</option>
                    <option value="DO">Dominican Republic</option>
                    <option value="EC">Ecuador</option>
                    <option value="EG">Egypt</option>
                    <option value="SV">El Salvador</option>
                    <option value="GQ">Equatorial Guinea</option>
                    <option value="ER">Eritrea</option>
                    <option value="EE">Estonia</option>
                    <option value="ET">Ethiopia</option>
                    <option value="FK">
                      Falkland Islands (Malvinas)
                    </option>
                    <option value="FO">Faroe Islands</option>
                    <option value="FJ">Fiji</option>
                    <option value="FI">Finland</option>
                    <option value="FR">France</option>
                    <option value="GF">French Guiana</option>
                    <option value="PF">French Polynesia</option>
                    <option value="TF">
                      French Southern Territories
                    </option>
                    <option value="GA">Gabon</option>
                    <option value="GM">Gambia</option>
                    <option value="GE">Georgia</option>
                    <option value="DE">Germany</option>
                    <option value="GH">Ghana</option>
                    <option value="GI">Gibraltar</option>
                    <option value="GR">Greece</option>
                    <option value="GL">Greenland</option>
                    <option value="GD">Grenada</option>
                    <option value="GP">Guadeloupe</option>
                    <option value="GU">Guam</option>
                    <option value="GT">Guatemala</option>
                    <option value="GG">Guernsey</option>
                    <option value="GN">Guinea</option>
                    <option value="GW">Guinea-Bissau</option>
                    <option value="GY">Guyana</option>
                    <option value="HT">Haiti</option>
                    <option value="HM">
                      Heard Island and McDonald Islands
                    </option>
                    <option value="VA">
                      Holy See (Vatican City State)
                    </option>
                    <option value="HN">Honduras</option>
                    <option value="HK">Hong Kong</option>
                    <option value="HU">Hungary</option>
                    <option value="IS">Iceland</option>
                    <option value="IN">India</option>
                    <option value="ID">Indonesia</option>
                    <option value="IR">Iran, Islamic Republic of</option>
                    <option value="IQ">Iraq</option>
                    <option value="IE">Ireland</option>
                    <option value="IM">Isle of Man</option>
                    <option value="IL">Israel</option>
                    <option value="IT">Italy</option>
                    <option value="JM">Jamaica</option>
                    <option value="JP">Japan</option>
                    <option value="JE">Jersey</option>
                    <option value="JO">Jordan</option>
                    <option value="KZ">Kazakhstan</option>
                    <option value="KE">Kenya</option>
                    <option value="KI">Kiribati</option>
                    <option value="KP">
                      Korea, Democratic People's Republic of
                    </option>
                    <option value="KR">Korea, Republic of</option>
                    <option value="KW">Kuwait</option>
                    <option value="KG">Kyrgyzstan</option>
                    <option value="LA">
                      Lao People's Democratic Republic
                    </option>
                    <option value="LV">Latvia</option>
                    <option value="LB">Lebanon</option>
                    <option value="LS">Lesotho</option>
                    <option value="LR">Liberia</option>
                    <option value="LY">Libyan Arab Jamahiriya</option>
                    <option value="LI">Liechtenstein</option>
                    <option value="LT">Lithuania</option>
                    <option value="LU">Luxembourg</option>
                    <option value="MO">Macao</option>
                    <option value="MK">
                      Macedonia, the former Yugoslav Republic of
                    </option>
                    <option value="MG">Madagascar</option>
                    <option value="MW">Malawi</option>
                    <option value="MY">Malaysia</option>
                    <option value="MV">Maldives</option>
                    <option value="ML">Mali</option>
                    <option value="MT">Malta</option>
                    <option value="MH">Marshall Islands</option>
                    <option value="MQ">Martinique</option>
                    <option value="MR">Mauritania</option>
                    <option value="MU">Mauritius</option>
                    <option value="YT">Mayotte</option>
                    <option value="MX">Mexico</option>
                    <option value="FM">
                      Micronesia, Federated States of
                    </option>
                    <option value="MD">Moldova, Republic of</option>
                    <option value="MC">Monaco</option>
                    <option value="MN">Mongolia</option>
                    <option value="ME">Montenegro</option>
                    <option value="MS">Montserrat</option>
                    <option value="MA">Morocco</option>
                    <option value="MZ">Mozambique</option>
                    <option value="MM">Myanmar</option>
                    <option value="NA">Namibia</option>
                    <option value="NR">Nauru</option>
                    <option value="NP">Nepal</option>
                    <option value="NL">Netherlands</option>
                    <option value="AN">Netherlands Antilles</option>
                    <option value="NC">New Caledonia</option>
                    <option value="NZ">New Zealand</option>
                    <option value="NI">Nicaragua</option>
                    <option value="NE">Niger</option>
                    <option value="NG">Nigeria</option>
                    <option value="NU">Niue</option>
                    <option value="NF">Norfolk Island</option>
                    <option value="MP">Northern Mariana Islands</option>
                    <option value="NO">Norway</option>
                    <option value="OM">Oman</option>
                    <option value="PK">Pakistan</option>
                    <option value="PW">Palau</option>
                    <option value="PS">
                      Palestinian Territory, Occupied
                    </option>
                    <option value="PA">Panama</option>
                    <option value="PG">Papua New Guinea</option>
                    <option value="PY">Paraguay</option>
                    <option value="PE">Peru</option>
                    <option value="PH">Philippines</option>
                    <option value="PN">Pitcairn</option>
                    <option value="PL">Poland</option>
                    <option value="PT">Portugal</option>
                    <option value="PR">Puerto Rico</option>
                    <option value="QA">Qatar</option>
                    <option value="RE">Réunion</option>
                    <option value="RO">Romania</option>
                    <option value="RU">Russian Federation</option>
                    <option value="RW">Rwanda</option>
                    <option value="BL">Saint Barthélemy</option>
                    <option value="SH">Saint Helena</option>
                    <option value="KN">Saint Kitts and Nevis</option>
                    <option value="LC">Saint Lucia</option>
                    <option value="MF">Saint Martin (French part)</option>
                    <option value="PM">Saint Pierre and Miquelon</option>
                    <option value="VC">
                      Saint Vincent and the Grenadines
                    </option>
                    <option value="WS">Samoa</option>
                    <option value="SM">San Marino</option>
                    <option value="ST">Sao Tome and Principe</option>
                    <option value="SA">Saudi Arabia</option>
                    <option value="SN">Senegal</option>
                    <option value="RS">Serbia</option>
                    <option value="SC">Seychelles</option>
                    <option value="SL">Sierra Leone</option>
                    <option value="SG">Singapore</option>
                    <option value="SK">Slovakia</option>
                    <option value="SI">Slovenia</option>
                    <option value="SB">Solomon Islands</option>
                    <option value="SO">Somalia</option>
                    <option value="ZA">South Africa</option>
                    <option value="GS">
                      South Georgia and the South Sandwich Islands
                    </option>
                    <option value="ES">Spain</option>
                    <option value="LK">Sri Lanka</option>
                    <option value="SD">Sudan</option>
                    <option value="SR">Suriname</option>
                    <option value="SJ">Svalbard and Jan Mayen</option>
                    <option value="SZ">Swaziland</option>
                    <option value="SE">Sweden</option>
                    <option value="CH">Switzerland</option>
                    <option value="SY">Syrian Arab Republic</option>
                    <option value="TW">Taiwan, Province of China</option>
                    <option value="TJ">Tajikistan</option>
                    <option value="TZ">
                      Tanzania, United Republic of
                    </option>
                    <option value="TH">Thailand</option>
                    <option value="TL">Timor-Leste</option>
                    <option value="TG">Togo</option>
                    <option value="TK">Tokelau</option>
                    <option value="TO">Tonga</option>
                    <option value="TT">Trinidad and Tobago</option>
                    <option value="TN">Tunisia</option>
                    <option value="TR">Turkey</option>
                    <option value="TM">Turkmenistan</option>
                    <option value="TC">Turks and Caicos Islands</option>
                    <option value="TV">Tuvalu</option>
                    <option value="UG">Uganda</option>
                    <option value="UA">Ukraine</option>
                    <option value="AE">United Arab Emirates</option>
                    <option value="GB">United Kingdom</option>
                    <option value="US">United States</option>
                    <option value="UM">
                      United States Minor Outlying Islands
                    </option>
                    <option value="UY">Uruguay</option>
                    <option value="UZ">Uzbekistan</option>
                    <option value="VU">Vanuatu</option>
                    <option value="VE">
                      Venezuela, Bolivarian Republic of
                    </option>
                    <option value="VN">Viet Nam</option>
                    <option value="VG">Virgin Islands, British</option>
                    <option value="VI">Virgin Islands, U.S.</option>
                    <option value="WF">Wallis and Futuna</option>
                    <option value="EH">Western Sahara</option>
                    <option value="YE">Yemen</option>
                    <option value="ZM">Zambia</option>
                    <option value="ZW">Zimbabwe</option>
                  </select>
                </div>
                <div className="col-12">
                  <label className="switch">
                    <input type="checkbox" className="switch-input" />
                    <span className="switch-toggle-slider">
                      <span className="switch-on"></span>
                      <span className="switch-off"></span>
                    </span>
                    <span className="switch-label">
                      Use as a billing address?
                    </span>
                  </label>
                </div>
                <div className="col-12 text-center">
                  <button
                    type="submit"
                    className="btn btn-primary me-sm-3 me-1"
                  >
                    Submit
                  </button>
                  <button
                    type="reset"
                    className="btn btn-label-secondary"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>

          {/* <div
            className="modal fade"
            id="editUser"
            tabindex="-1"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-simple modal-edit-user">
              <div className="modal-content p-3 p-md-5">
                <div className="modal-body">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                  <div className="text-center mb-4">
                    <h3 className="mb-2">Edit User Information</h3>
                    <p className="text-muted">
                      Updating user details will receive a privacy audit.
                    </p>
                  </div>
                  <form
                    id="editUserForm"
                    className="row g-3"
                    onsubmit="return false"
                  >
                    <div className="col-12 col-md-6">
                      <label
                        className="form-label"
                        for="modalEditUserFirstName"
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        id="modalEditUserFirstName"
                        name="modalEditUserFirstName"
                        className="form-control"
                        placeholder="John"
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="form-label" for="modalEditUserLastName">
                        Last Name
                      </label>
                      <input
                        type="text"
                        id="modalEditUserLastName"
                        name="modalEditUserLastName"
                        className="form-control"
                        placeholder="Doe"
                      />
                    </div>
                    <div className="col-12">
                      <label className="form-label" for="modalEditUserName">
                        Username
                      </label>
                      <input
                        type="text"
                        id="modalEditUserName"
                        name="modalEditUserName"
                        className="form-control"
                        placeholder="john.doe.007"
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="form-label" for="modalEditUserEmail">
                        Email
                      </label>
                      <input
                        type="text"
                        id="modalEditUserEmail"
                        name="modalEditUserEmail"
                        className="form-control"
                        placeholder="example@domain.com"
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="form-label" for="modalEditUserStatus">
                        Status
                      </label>
                      <select
                        id="modalEditUserStatus"
                        name="modalEditUserStatus"
                        className="select2 form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Status</option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                        <option value="3">Suspended</option>
                      </select>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="form-label" for="modalEditTaxID">
                        Tax ID
                      </label>
                      <input
                        type="text"
                        id="modalEditTaxID"
                        name="modalEditTaxID"
                        className="form-control modal-edit-tax-id"
                        placeholder="123 456 7890"
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="form-label" for="modalEditUserPhone">
                        Phone Number
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">US (+1)</span>
                        <input
                          type="text"
                          id="modalEditUserPhone"
                          name="modalEditUserPhone"
                          className="form-control phone-number-mask"
                          placeholder="202 555 0111"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="form-label" for="modalEditUserCountry">
                        Language
                      </label>
                      <select
                        id="modalEditUserCountry"
                        name="modalEditUserCountry"
                        className="select2 form-select"
                        data-allow-clear="true"
                      >
                         <option value="">Select</option>
                        <option value="english">English</option>
                        <option value="spanish">Spanish</option>
                        <option value="french">French</option>
                        <option value="german">German</option>
                        <option value="dutch">Dutch</option>
                        <option value="hebrew">Hebrew</option>
                        <option value="sanskrit">Sanskrit</option>
                        <option value="hindi">Hindi</option>
                      </select>
                    </div>
                    
                    <div className="col-12 col-md-6">
                      <label className="form-label" for="modalEditUserCountry">
                        Country
                      </label>
                      <select
                        id="modalEditUserCountry"
                        name="modalEditUserCountry"
                        className="select2 form-select"
                        data-allow-clear="true"
                      >
                        <option value="">Select</option>
                        <option value="Australia">Australia</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Brazil">Brazil</option>
                        <option value="Canada">Canada</option>
                        <option value="China">China</option>
                        <option value="France">France</option>
                        <option value="Germany">Germany</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Japan">Japan</option>
                        <option value="Korea">Korea, Republic of</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Russia">Russian Federation</option>
                        <option value="South Africa">South Africa</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">
                          United Arab Emirates
                        </option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                      </select>
                    </div>
                    <div className="col-12">
                      <label className="switch">
                        <input type="checkbox" className="switch-input" />
                        <span className="switch-toggle-slider">
                          <span className="switch-on"></span>
                          <span className="switch-off"></span>
                        </span>
                        <span className="switch-label">
                          Use as a billing address?
                        </span>
                      </label>
                    </div>
                    <div className="col-12 text-center">
                      <button
                        type="submit"
                        className="btn btn-primary me-sm-3 me-1"
                      >
                        Submit
                      </button>
                      <button
                        type="reset"
                        className="btn btn-label-secondary"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
