import { get, post, put } from '../index';
import ChatbotRoutes from './Chatbot.Routes';

const ChatbotServices = {
  getChatbotSetting: async (params) => {
    const data = await get(ChatbotRoutes.getChatbotSetting, params);
    return data;
  },
  updateChatbotSetting: async (obj) => {
    const data = await post(ChatbotRoutes.updateChatbotSetting, obj);
    return data;
  },
  uploadChatbotAvatar: async (id, obj) => {
    const data = await post(ChatbotRoutes.uploadChatbotAvatar + `projectId=${id}`, obj);
    return data;
  },
  uploadChatbotBg: async (id, obj) => {
    const data = await post(ChatbotRoutes.uploadChatbotBg + `projectId=${id}`, obj);
    return data;
  },
}

export default ChatbotServices