import React, { useEffect, useState } from "react";
import Wrapper from "../wrapper/wrapper";
import {
  IconArtboard,
  IconBrush,
  IconDental,
  IconTruck,
  IconDeviceDesktop,
} from "@tabler/icons-react";
import { Card, Button } from "react-bootstrap";
import AddonServices from "../apis/addon/AddonServices";
import { ErrorToaster } from "../components/Toaster";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/spinner";

export default function AddsOn(props) {

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);

  const [addons, setAddons] = useState();

  const getAddons = async () => {
    setLoader(true)
    try {
      const { data } = await AddonServices.getAddons()
      setAddons(data)
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    getAddons()
  }, []);

  return (
    <Wrapper>
      <div className="layout-container">
        <div className="layout-page">
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <h2 className="settings-header mt-6">
                <span className="text-muted fw-bold">{props.name}&nbsp;</span>
                {/* <span>Test 2</span> */}
              </h2>
              {loader ? (
                <Spinner size='large' />
              ) : (
                <div className="row">

                  {addons?.map((item, index) => (
                    <div key={index} class="col-xl-4 col-md-6 mb-4">
                      <Card>
                        <Card.Body>
                          <div className="d-flex align-items-center mb-3">
                            <span class="avatar-initial rounded bg-label-primary p-1">
                              <IconDeviceDesktop size={32} className="mr-2" />
                            </span>
                            <div className="ml-5">
                              <Card.Title>{item?.name}</Card.Title>
                              <Card.Subtitle className="text-muted">
                                {item?.features?.subtitle}
                              </Card.Subtitle>
                            </div>
                          </div>
                          <Card.Text>
                            {item?.features?.Description}
                          </Card.Text>
                          <div className="d-flex justify-content-between align-items-center mt-3 py-2">
                            <Card.Text
                              style={{
                                fontSize: "24px",
                                fontWeight: "bold",
                                color: "#61d9ca",
                              }}
                            >
                              ${item?.price}
                            </Card.Text>
                            <Button className="bg-primary" onClick={() => navigate('/payment', { state: { ...item, isAddOn: true } })}>Buy</Button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}

                  {/* <div class="col-xl-4 col-md-6 mb-4">
                  <Card>
                    <Card.Body>
                      <div className="d-flex align-items-center mb-3">
                        <span class="avatar-initial rounded bg-label-danger p-1">
                          <IconBrush size={32} className="mr-2" />
                        </span>
                        <div className="ml-5">
                          <Card.Title>Dental Branding</Card.Title>
                          <Card.Subtitle className="text-muted">
                            Craft a Lasting Impression
                          </Card.Subtitle>
                        </div>
                      </div>
                      <Card.Text>
                        Craft a compelling brand identity that resonates with
                        your audience, setting your dental practice apart from
                        the rest
                      </Card.Text>
                      <div className="d-flex justify-content-between align-items-center mt-3 py-2">
                        <Card.Text
                          style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            color: "#61d9ca",
                          }}
                        >
                          $250
                        </Card.Text>
                        <Button className="bg-primary">Buy</Button>
                      </div>
                    </Card.Body>
                  </Card>
                </div> */}

                  {/* <div class="col-xl-4 col-lg-6 mb-4 order-2 order-xl-0">
                  <Card>
                    <Card.Body>
                      <div className="d-flex align-items-center mb-3">
                        <span class="avatar-initial rounded bg-label-primary p-1">
                          <IconDeviceDesktop size={32} className="mr-2" />
                        </span>
                        <div className="ml-5">
                          <Card.Title>Web Design</Card.Title>
                          <Card.Subtitle className="text-muted">
                            Tailored for Dental Excellence
                          </Card.Subtitle>
                        </div>
                      </div>
                      <Card.Text>
                        Our experts craft visually stunning, user-friendly
                        websites that captivate and convert for your dental
                        practice.
                      </Card.Text>
                      <div className="d-flex justify-content-between align-items-center mt-3 py-2">
                        <Card.Text
                          style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            color: "#61d9ca",
                          }}
                        >
                          $450
                        </Card.Text>
                        <Button className="bg-primary">Buy</Button>
                      </div>
                    </Card.Body>
                  </Card>
                </div> */}

                </div>
              )}
            </div>

            <div class="content-backdrop fade"></div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
