import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../assets/css/login.scss";
import "../assets/vendor/css/rtl/core.css";
import "../assets/vendor/css/rtl/theme-default.css";
import Logo from "../assets/img/logo.svg";
import { IconEyeOff, IconEye } from "@tabler/icons-react";
import { Form, Input } from "antd";
import AuthServices from "../apis/auth/AuthServices";
import { useAuth } from "../context/UseContext";
import { ErrorToaster } from "../components/Toaster";

export default function Login() {

  const [showPassword, setShowPassword] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { userLogin } = useAuth();

  const onFinish = async (formData) => {
    setLoading(true)
    try {
      let obj = {
        email: formData?.email,
        password: formData?.password
      }
      const { user } = await AuthServices.login(obj)
      userLogin(user)
    } catch (error) {
      ErrorToaster('You have entered an invalid email or password.')
    } finally {
      setLoading(false)
    }
  };

  return (
    <div>
      <div class="container-xxl">
        <div class="authentication-wrapper authentication-basic container-p-y">
          <div class="authentication-inner py-4">
            <div class="card">
              <div class="card-body">
                <div class="app-brand justify-content-center mb-4 mt-2">
                  <a href="/" class="app-brand-link gap-2">
                    <span class="app-brand-logo demo">
                      <img src={Logo} className="login-logo" />
                    </span>
                  </a>
                </div>
                <p class="mb-4">
                  Please sign-in to your account and start the adventure
                </p>

                <Form onFinish={onFinish} form={form} name="basic">
                  <div className="mb-3">
                    <label for="email" class="form-label">
                      Email or Username
                    </label>
                    <Form.Item
                      name="email"
                      rules={[{ required: true, message: "Email is required" }]}
                    >
                      <Input
                        className="form-control"
                        placeholder="Enter your email or username"
                      />
                    </Form.Item>
                  </div>

                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <label for="password" class="form-label">
                        Password
                      </label>
                      <Link to="/forgot-password" className="created">
                        <small>Forgot Password?</small>
                      </Link>
                    </div>
                    <Form.Item
                      name="password"
                      rules={[
                        { required: true, message: "Password is required" },
                      ]}
                    >
                      <div className="input-group input-group-merge">
                        <Input
                          className="form-control"
                          type={`${!showPassword ? "password" : "text"}`}
                          placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                        />
                        <span className=" input-group-text cursor-pointer">
                          {showPassword ? (
                            <IconEye onClick={() => setShowPassword(false)} />
                          ) : (
                            <IconEyeOff onClick={() => setShowPassword(true)} />
                          )}
                        </span>
                      </div>
                    </Form.Item>
                  </div>
                  {/* <div class="mb-3">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="remember-me"
                      />
                      <label class="form-check-label" for="remember-me">
                        {" "}
                        Remember Me{" "}
                      </label>
                    </div>
                  </div> */}
                  <button disabled={loading} class="btn btn-primary d-grid w-100" type="submit">
                    Sign in
                  </button>
                </Form>

                <p class="text-center mt-2 py-0">
                  <span>New on our platform?</span>
                  <Link to="/register">
                    <span className="created"> Create an account</span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
