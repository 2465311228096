import { Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import '../assets/css/paymentpage.scss'
export default function PaymentSuccess() {
    const navigate = useNavigate();
    const gotoDashboard = ()=>{
        navigate('/')
    }
    return (
        <div className="payment-success-page">
            <div className="card">
                <img className="logo" src="https://www.kriss.ai/wp-content/uploads/2024/01/Logo-Kriss-Black.png" alt="Kriss.AI Logo" />
                <div className="checkmark-main">
                <i className="checkmark">✓</i>
                </div>
                <h1>Payment Successful!</h1>
                <p>Welcome To Kriss.AI.<br />You are now enrolled</p>
                <button className="continue-button" onClick={()=>{gotoDashboard()}}>Go to Dashboard</button>
            </div>
        </div>
    )
}