const SubscriptionRoutes = {
  getStripeSecretKey: '/payment',
  getSubscriptionPlans: 'plan/country',
  upgradePlan: 'plan/updatecurrent',
  getSetupFees: 'setupfees',
  getCardDetail: 'retrievecard',
  updateCardDetail: 'updatecarddetails',
  removeCard: 'remove-card'
};

export default SubscriptionRoutes