const ProfileRoutes = {
  getProfileDetails: 'users/getProfileDetails',
  updateProfile: 'users/updateProfile',
  resetPassword: 'users/resetPassword',
  deactivateAccount: 'users/deactivateAccount',
  uploadProfilePicture: 'users/uploadImage',
  accountSetup: 'users/accountsetup',
  getAccountSetup: 'customers/accountsetupinfo',
  getCurrentPlan: 'users/currentplan',
  cancelSubscription: 'plan/cancel',
  getPaymentHistory: 'users/paymenthistory',
};

export default ProfileRoutes