import React, { useState, useEffect, useRef } from "react";
import girl from "../assets/img/illustrations/girl-with-laptop.png";
import Select from "react-select";
import "../../src/assets/vendor/css/pages/page-account-settings.css"
import {
  IconEye,
  IconEyeOff,
} from "@tabler/icons-react";
import ProfileServices from "../apis/profile/ProfileServices";
import { ErrorToaster, SuccessToaster } from "./Toaster";
import { useForm } from "react-hook-form";

export default function Security() {

  const { register, handleSubmit, formState: { errors }, watch, reset } = useForm();
  const password = useRef({});
  password.current = watch("newPassword", "");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmpass, setShowConfirmpass] = useState(false);
  const [newPassword, setNewPassword] = useState(false);

  useEffect(() => {
    document.body.classList.add("modal-style-blur");
  }, []);

  // This is componentWillUnmount
  useEffect(() => {
    return () => {
      //  componentWillUnmount.current = true;
      document.body.classList.remove("modal-style-blur");
    };
  }, []);

  const resetPassword = async (formData) => {
    try {
      let params = {
        oldPassword: formData?.oldPassword,
        newPassword: formData?.newPassword,
        newConfirmPassword: formData?.confirmPassword,
      }
      const { message } = await ProfileServices.resetPassword(params)
      SuccessToaster(message)
      reset()
    } catch (error) {
      ErrorToaster(error)
    }
  }

  return (
    <div>
      <div className="card mb-4">
        <h5 className="card-header">Change Password</h5>
        <div className="card-body">
          <form id="formAccountSettings" method="POST" onsubmit="return false">
            <div className="row">
              <div className="mb-3 col-md-6 form-password-toggle">
                <label className="form-label" for="currentPassword">
                  Current Password
                </label>
                <div className="input-group input-group-merge">
                  <input
                    className="form-control"
                    name="currentPassword"
                    id="currentPassword"
                    type={`${!showPassword ? "password" : "text"}`}
                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                    {...register('oldPassword', {
                      required: 'Please enter old password'
                    })}
                  />
                  <span className=" input-group-text cursor-pointer">
                    {showPassword ? (
                      <IconEye onClick={() => setShowPassword(false)} />
                    ) : (
                      <IconEyeOff onClick={() => setShowPassword(true)} />
                    )}
                  </span>
                </div>
                {errors &&
                  <p className={'error'}>{errors?.oldPassword?.message}</p>
                }
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-md-6 form-password-toggle">
                <label className="form-label" for="newPassword">
                  New Password
                </label>
                <div className="input-group input-group-merge">
                  <input
                    className="form-control"
                    type={`${!newPassword ? "password" : "text"}`}
                    id="newPassword"
                    name="newPassword"
                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                    {...register('newPassword', {
                      required: 'Please enter password',
                      minLength: {
                        value: 6,
                        message: "Password should be atleast 6 characters",
                      },
                    })}
                  />
                  <span className=" input-group-text cursor-pointer">
                    {newPassword ? (
                      <IconEye onClick={() => setNewPassword(false)} />
                    ) : (
                      <IconEyeOff onClick={() => setNewPassword(true)} />
                    )}
                  </span>
                </div>
                {errors &&
                  <p className={'error'}>{errors?.newPassword?.message}</p>
                }
              </div>

              <div className="mb-3 col-md-6 form-password-toggle">
                <label className="form-label" for="confirmPassword">
                  Confirm New Password
                </label>
                <div className="input-group input-group-merge">
                  <input
                    className="form-control"
                    type={`${!showConfirmpass ? "password" : "text"}`}
                    name="confirmPassword"
                    id="confirmPassword"
                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                    {...register('confirmPassword', {
                      required: 'Please enter the confirm password',
                      validate: value => value === password.current || "Confirm password does not match."
                    })}
                  />
                  <span className=" input-group-text cursor-pointer">
                    {showConfirmpass ? (
                      <IconEye onClick={() => setShowConfirmpass(false)} />
                    ) : (
                      <IconEyeOff onClick={() => setShowConfirmpass(true)} />
                    )}
                  </span>
                </div>
                {errors &&
                  <p className={'error'}>{errors?.confirmPassword?.message}</p>
                }
              </div>
              <div className="col-12 mb-4">
                <h6>Password Requirements:</h6>
                <ul className="ps-3 mb-0">
                  <li className="mb-1">
                    Minimum 6 characters long - the more, the better
                  </li>
                  {/* <li className="mb-1">At least one lowercase character</li>
                  <li>At least one number, symbol, or whitespace character</li> */}
                </ul>
              </div>
              <div>
                <button type="submit" className="btn btn-primary me-2" onClick={handleSubmit(resetPassword)}>
                  Save changes
                </button>
                {/* <button type="reset" className="btn btn-label-secondary">
                  Cancel
                </button> */}
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* <div className="card mb-4">
        <h4 className="card-header">Two-steps verification</h4>

        <div className="card-body">
        <p >Add additional security to your account using two factor authentication.
</p>
          <div className="mb-3 v-alert">
            <div className="v-alert-title">
            Two factor authentication is not enabled yet.
            </div>
          </div>
          <p className="w-75">
          When two factor authentication is enabled, you will be prompted for a secure, random token during authentication. You may retrieve this token from your phone's Google Authenticator application.

          </p>
          <button
            className="btn btn-primary mt-2"
            data-bs-toggle="modal"
            data-bs-target="#enableOTP"
          >
            Enable
          </button>
        </div>
      </div> */}


      <div
        className="modal fade"
        id="enableOTP"
        tabindex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-simple modal-enable-otp modal-dialog-centered">
          <div className="modal-content p-3 p-md-5">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="text-center mb-4">
                <h3 className="mb-2">Confirm Password</h3>
                <p>For your security, please confirm your password to continue.</p>
              </div>
              {/* <p>
                Enter your mobile phone number with country code and we will
                send you a verification code.
              </p> */}
              <form
                id="enableOTPForm"
                className="row g-3"
                onsubmit="return false"
              >
                <div className="col-12">

                  <div className="input-group">
                    <input
                      type="password"
                      id="modalEnableOTPPhone"
                      name="modalEnableOTPPhone"
                      className="form-control phone-number-otp-mask"
                      placeholder="Password"
                    />
                  </div>
                </div>
                <div className="w-100 text-center">
                  <button
                    type="reset"
                    className="btn btn-label-secondary"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary me-sm-3 me-1"
                  >
                    Confirm
                  </button>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="card mb-4">
        <h5 className="card-header">Create an API key</h5>
        <div className="row">
          <div className="col-md-5 order-md-0 order-1">
            <div className="card-body">
              <form
                id="formAccountSettingsApiKey"
                method="POST"
                onsubmit="return false"
              >
                <div className="row">
                  <div className="mb-3 col-12">
                    <label for="apiAccess" className="form-label">
                      Choose the Api key type you want to create
                    </label>

                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={colourOptions[0]}
                      isSearchable={isSearchable}
                      name="color"
                      options={colourOptions}
                    />
                  </div>
                  <div className="mb-3 col-12">
                    <label for="apiKey" className="form-label">
                      Name the API key
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="apiKey"
                      name="apiKey"
                      placeholder="Server Key 1"
                    />
                  </div>
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-primary me-2 d-grid w-100"
                    >
                      Create Key
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-7 order-md-1 order-0">
            <div className="text-center mt-4 mx-3 mx-md-0">
              <img
                src={girl}
                className="img-fluid"
                alt="Api Key Image"
                width="202"
              />
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="card mb-4">
        <h5 className="card-header">API Key List & Access</h5>
        <div className="card-body">
          <p>
            An API key is a simple encrypted string that identifies an
            application without any principal. They are useful for accessing
            public data anonymously, and are used to associate API requests with
            your project for quota and billing.
          </p>
          <div className="row">
            <div className="col-md-12">
              <div className="bg-lighter rounded p-3 mb-3 position-relative">
                <div className="dropdown api-key-actions">
                  <a
                    className="btn dropdown-toggle text-muted hide-arrow p-0"
                    data-bs-toggle="dropdown"
                  >
                    <IconDotsVertical  className="ti ti-dots-vertical ti-sm" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-end">
                    <a href="javascript:;" className="dropdown-item">
                    <IconPencil className="me-2" /> Edit
                    </a>
                    <a href="javascript:;" className="dropdown-item">
                    <IconTrash className="me-2" /> Delete
                    </a>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <h4 className="mb-0 me-3">Server Key 1</h4>
                  <span className="badge bg-label-primary">Full Access</span>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <p className="me-2 mb-0 fw-medium">
                    23eaf7f0-f4f7-495e-8b86-fad3261282ac
                  </p>
                  <span className="text-muted cursor-pointer">
                   <IconCopy />
                  </span>

                </div>
                <span className="text-muted">
                  Created on 28 Apr 2021, 18:20 GTM+4:10
                </span>
              </div>
              <div className="bg-lighter rounded p-3 position-relative mb-3">
                <div className="dropdown api-key-actions">
                  <a
                    className="btn dropdown-toggle text-muted hide-arrow p-0"
                    data-bs-toggle="dropdown"
                  >
                    <IconDotsVertical  className="ti ti-dots-vertical ti-sm" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-end">
                    <a href="javascript:;" className="dropdown-item">
                      <IconPencil className="me-2" /> Edit
                    </a>
                    <a href="javascript:;" className="dropdown-item">
                      <IconTrash className="me-2" /> Delete
                    </a>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <h4 className="mb-0 me-3">Server Key 2</h4>
                  <span className="badge bg-label-primary">Read Only</span>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <p className="me-2 mb-0 fw-medium">
                    bb98e571-a2e2-4de8-90a9-2e231b5e99
                  </p>
                  <span className="text-muted cursor-pointer">
                    <i className="ti ti-copy ti-sm"></i>
                  </span>
                </div>
                <span className="text-muted">
                  Created on 12 Feb 2021, 10:30 GTM+2:30
                </span>
              </div>
              <div className="bg-lighter rounded p-3 position-relative">
                <div className="dropdown api-key-actions">
                  <a
                    className="btn dropdown-toggle text-muted hide-arrow p-0 text-end"
                    data-bs-toggle="dropdown"
                  >
                    <IconDotsVertical  className="ti ti-dots-vertical ti-sm" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-end">
                    <a href="javascript:;" className="dropdown-item">
                    <IconPencil className="me-2" />Edit
                    </a>
                    <a href="javascript:;" className="dropdown-item">
                    <IconTrash className="me-2" /> Delete
                    </a>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <h4 className="mb-0 me-3">Server Key 3</h4>
                  <span className="badge bg-label-primary">Full Access</span>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <p className="me-2 mb-0 fw-medium">
                    2e915e59-3105-47f2-8838-6e46bf83b711
                  </p>
                  <span className="text-muted cursor-pointer">
                    <i className="ti ti-copy ti-sm"></i>
                  </span>
                </div>
                <span className="text-muted">
                  Created on 28 Dec 2020, 12:21 GTM+4:10
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card mb-4">
        <h5 className="card-header">Recent Devices</h5>
        <div className="table-responsive">
          <table className="table border-top">
            <thead className="table-light">
              <tr>
                <th className="text-truncate">Browser</th>
                <th className="text-truncate">Device</th>
                <th className="text-truncate">Location</th>
                <th className="text-truncate">Recent Activities</th>
              </tr>
            </thead>
            <tbody className="table-border-bottom-0">
              <tr>
                <td className="text-truncate">
                  <IconBrandWindows className="text-info me-2" size={20} />
                  <span className="fw-medium">Chrome on Windows</span>
                </td>
                <td className="text-truncate">HP Spectre 360</td>
                <td className="text-truncate">Switzerland</td>
                <td className="text-truncate">10, July 2021 20:07</td>
              </tr>
              <tr>
                <td className="text-truncate">
                  <IconDeviceMobile className="text-danger me-2" size={20} />
                  <span className="fw-medium">Chrome on iPhone</span>
                </td>
                <td className="text-truncate">iPhone 12x</td>
                <td className="text-truncate">Australia</td>
                <td className="text-truncate">13, July 2021 10:10</td>
              </tr>
              <tr>
                <td className="text-truncate">
                  <IconBrandAndroid className="text-success me-2 " size={20} />
                  <i className="ti ti-brand-android ti-sm"></i>
                  <span className="fw-medium">Chrome on Android</span>
                </td>
                <td className="text-truncate">Oneplus 9 Pro</td>
                <td className="text-truncate">Dubai</td>
                <td className="text-truncate">14, July 2021 15:15</td>
              </tr>
              <tr>
                <td className="text-truncate">
                  <IconBrandApple className=" me-2" size={20} />
                  <span className="fw-medium">Chrome on MacOS</span>
                </td>
                <td className="text-truncate">Apple iMac</td>
                <td className="text-truncate">India</td>
                <td className="text-truncate">20, July 2021 20:17</td>
              </tr>
              <tr>
                <td className="text-truncate">
                  <IconBrandWindows className="text-info me-2" size={20} />
                  <span className="fw-medium">Chrome on Windows</span>
                </td>
                <td className="text-truncate">HP Spectre 360</td>
                <td className="text-truncate">Switzerland</td>
                <td className="text-truncate">20, July 2021 21:01</td>
              </tr>
              <tr>
                <td className="text-truncate">
                  <IconBrandAndroid className="text-success me-2 " size={20} />
                  <span className="fw-medium">Chrome on Android</span>
                </td>
                <td className="text-truncate">Oneplus 9 Pro</td>
                <td className="text-truncate">Dubai</td>
                <td className="text-truncate">21, July 2021 12:22</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}
    </div>
  );
}
