import { get } from '../index';
import DashboardRoutes from './Dashboard.Routes';

const DashboardServices = {
  getDashboardStats: async (params) => {
    const data = await get(DashboardRoutes.getDashboardStats, params);
    return data;
  },
}

export default DashboardServices