import {
  IconEdit,
  IconHelp,
  IconLink,
  IconPhoto,
  IconQuestionMark,
  IconTrash,
} from "@tabler/icons-react";
import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import laptop from "../assets/img/illustrations/girl-with-laptop.png";
import "../assets/vendor/css/rtl/core.css";
import "../assets/vendor/css/rtl/theme-default.css";
import Wrapper from "../wrapper/wrapper";
import { useParams } from 'react-router';
import { ErrorToaster } from './Toaster';
import { useAuth } from '../context/UseContext';
import PageServices from '../apis/pages/PageServices';

export default function MetaData() {

  const { id } = useParams();
  const { user } = useAuth();

  const [metaData, setMetaData] = useState();

  const [showDiv, setShowDiv] = useState([
    {
      show: false,
    },
    {
      show: false,
    },
    {
      show: false,
    },
    {
      show: false,
    },
  ]);

  // ye div ko visible or unvisible kr rha
  const toggleDiv = (index) => {
    setShowDiv([...showDiv, (showDiv[index].show = !showDiv[index].show)]);
  };

  const tooltipone = (
    <Tooltip id="tooltipfour">
      The title (max 60 chars) to be shown in the citations.
    </Tooltip>
  );

  const tooltiptwo = (
    <Tooltip id="tooltiptwo">
      The URL to be linked when this page is shown in citations.
    </Tooltip>
  );

  const tooltipthree = (
    <Tooltip id="tooltipthree">
      An image url which should shown when this image is shown in citations.
    </Tooltip>
  );

  const tooltipfour = (
    <Tooltip id="tooltipfour">
      A one to two sentences of this page (max 500 chars).
    </Tooltip>
  );

  // ye edit btn ke click pr chl rha
  const notifyEditSuccess = (index) => {
    setShowDiv([...showDiv, (showDiv[index].show = !showDiv[index].show)]);
    // write your notification logic here  logic here
  };

  const getPageMetaData = async () => {
    try {
      let params = {
        projectId: user?.projectId,
        pageId: id
      }
      const { data } = await PageServices.getPageMetaData(params)
      setMetaData(data)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  useEffect(() => {
    if (id) {
      getPageMetaData()
    }
  }, [id])

  return (
    <>
      <Wrapper>
        <div className="layout-container metadata-page">
          <div className="layout-page">
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="card mt-4 px-4 py-4 d-flex justify-content-between flex-row align-items-center">
                  <div className="d-flex justify-content-between ">
                    <div className="pr-4">
                      <h2 data-v-0008f0d1="" className="settings-header mt-3">
                        Page / Metadata
                      </h2>
                      <div className="d-flex align-items-center gap-2  metadata-icon-line">
                        <div className="flex-grow-0 metadata-icon">
                          <IconLink size={20} />
                        </div>
                        <a href="#" className="metadata-link">
                          {metaData?.title}
                        </a>
                      </div>
                      <div className="d-flex align-items-center gap-2  metadata-icon-line">
                        <div className="flex-grow-0 metadata-icon">
                          <IconQuestionMark size={20} />
                        </div>
                        <a href="#" className="metadata-link fw-normal">
                          {metaData?.description}
                        </a>
                      </div>
                      <div className="d-flex align-items-center gap-2  metadata-icon-line">
                        <div className="flex-grow-0 metadata-icon">
                          <IconPhoto size={20} />
                        </div>
                        <a href="#" className="metadata-link  fw-normal">
                          {metaData?.url}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div>
                    <img
                      src={laptop}
                      style={{ maxWidth: "180px", height: "auto" }}
                    />
                  </div>
                </div>

                <div
                  className="card mt-4 px-4 py-4 d-flex justify-content-between flex-row align-items-center"
                  style={{ padding: "24px" }}
                >
                  <div className="justify-content-between w-100 ">
                    <div
                      className="card p-0 w-100"
                      style={{ background: "#DFDFE3", borderRadius: "0px" }}
                    >
                      <div className="p-3">
                        <div className="d-flex justify-content-between align-items-center gap-2">
                          <div className="flex-grow-1">
                            <div className="d-flex align-items-center mb-3">
                              <label
                                data-v-3f66c954=""
                                className="v-label v-label--clickable text-sm select-none text-gray-700 me-3"
                                for="title"
                                style={{ opacity: "1" }}
                              >
                                <span>Title</span>
                              </label>
                              <div className="inline custom-tooltip">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltipone}
                                  className="w-100"
                                >
                                  <IconHelp />
                                </OverlayTrigger>
                              </div>
                            </div>
                            {!showDiv[0]?.show && (
                              <span
                                className="fw-bold word-break-all"
                                style={{ color: "black" }}
                              >
                                {metaData?.title}
                              </span>
                            )}
                          </div>
                          {!showDiv[0]?.show && (
                            <div className="d-flex gap-3">
                              <IconEdit
                                style={{ cursor: "pointer" }}
                                onClick={() => toggleDiv(0)}
                                color="black"
                              />
                              <IconTrash
                                style={{ cursor: "pointer" }}
                                color="black"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      {showDiv[0]?.show && (
                        <div>
                          <div className="container-fluid mt-3 pb-3">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                placeholder="Enter Title"
                                className="form-control"
                                id="basic-url"
                                aria-describedby="basic-addon3"
                                defaultValue={metaData?.title}
                              />
                            </div>
                            <button
                              type="button"
                              className="btn btn-primary me-2"
                              onClick={() => notifyEditSuccess(0)}
                            >
                              Edit
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => toggleDiv(0)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      className="card p-0 w-100 mt-3"
                      style={{ background: "#DFDFE3", borderRadius: "0px" }}
                    >
                      <div className="p-3">
                        <div className="d-flex justify-content-between align-items-center gap-2">
                          <div className="flex-grow-1">
                            <div className="d-flex align-items-center mb-3">
                              <label
                                data-v-3f66c954=""
                                className="v-label v-label--clickable text-sm select-none text-gray-700 me-3"
                                for="title"
                                style={{ opacity: "1" }}
                              >
                                <span>Url</span>
                              </label>
                              <div className="inline custom-tooltip">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltiptwo}
                                  className="w-100"
                                >
                                  <IconHelp />
                                </OverlayTrigger>
                              </div>
                            </div>
                            {!showDiv[1]?.show && (
                              <span
                                className="fw-bold word-break-all"
                                style={{ color: "black" }}
                              >
                                {metaData?.url}
                              </span>
                            )}
                          </div>
                          {!showDiv[1]?.show && (
                            <div className="d-flex gap-3">
                              <IconEdit
                                style={{ cursor: "pointer" }}
                                onClick={() => toggleDiv(1)}
                                color="black"
                              />
                              <IconTrash
                                style={{ cursor: "pointer" }}
                                color="black"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      {showDiv[1]?.show && (
                        <div>
                          <div className="container-fluid mt-3 pb-3">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                placeholder="Enter URL"
                                className="form-control"
                                id="basic-url"
                                aria-describedby="basic-addon3"
                                defaultValue={metaData?.url}
                              />
                            </div>
                            <button
                              type="button"
                              className="btn btn-primary me-2"
                              onClick={() => notifyEditSuccess(1)}
                            >
                              Edit{" "}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => toggleDiv(1)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      className="card p-0 w-100 mt-3"
                      style={{ background: "#DFDFE3", borderRadius: "0px" }}
                    >
                      <div className="p-3">
                        <div className="d-flex justify-content-between align-items-center gap-2">
                          <div className="flex-grow-1">
                            <div className="d-flex align-items-center mb-3">
                              <label
                                data-v-3f66c954=""
                                className="v-label v-label--clickable text-sm select-none text-gray-700 me-3"
                                for="title"
                                style={{ opacity: "1" }}
                              >
                                <span>Image Url</span>
                              </label>
                              <div className="inline custom-tooltip">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltipthree}
                                  className="w-100"
                                >
                                  <IconHelp />
                                </OverlayTrigger>
                              </div>
                            </div>
                            {!showDiv[2]?.show && (
                              <span
                                className="fw-bold word-break-all"
                                style={{ color: "black" }}
                              >
                                {metaData?.image}
                              </span>
                            )}
                          </div>
                          {!showDiv[2]?.show && (
                            <div className="d-flex gap-3">
                              <IconEdit
                                style={{ cursor: "pointer" }}
                                onClick={() => toggleDiv(2)}
                                color="black"
                              />
                              <IconTrash
                                style={{ cursor: "pointer" }}
                                color="black"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      {showDiv[2]?.show && (
                        <div>
                          <div className="container-fluid mt-3 pb-3">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                placeholder="Enter Image URL"
                                className="form-control"
                                id="basic-url"
                                aria-describedby="basic-addon3"
                                defaultValue={metaData?.image}
                              />
                            </div>
                            <button
                              type="button"
                              className="btn btn-primary me-2"
                              onClick={() => notifyEditSuccess(2)}
                            >
                              Edit{" "}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => toggleDiv(2)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      className="card p-0 w-100 mt-3"
                      style={{ background: "#DFDFE3", borderRadius: "0px" }}
                    >
                      <div className="p-3">
                        <div className="d-flex justify-content-between align-items-center gap-2">
                          <div className="flex-grow-1">
                            <div className="d-flex align-items-center mb-3">
                              <label
                                data-v-3f66c954=""
                                className="v-label v-label--clickable text-sm select-none text-gray-700 me-3"
                                for="title"
                                style={{ opacity: "1" }}
                              >
                                <span>Description</span>
                              </label>
                              <div className="inline custom-tooltip">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltipfour}
                                  className="w-100"
                                >
                                  <IconHelp />
                                </OverlayTrigger>
                              </div>
                            </div>
                            {!showDiv[3]?.show && (
                              <span
                                className="fw-bold word-break-all"
                                style={{ color: "black" }}
                              >
                                {metaData?.description}
                              </span>
                            )}
                          </div>
                          {!showDiv[3]?.show && (
                            <div className="d-flex gap-3">
                              <IconEdit
                                style={{ cursor: "pointer" }}
                                onClick={() => toggleDiv(3)}
                                color="black"
                              />
                              <IconTrash
                                style={{ cursor: "pointer" }}
                                color="black"
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      {showDiv[3]?.show && (
                        <div>
                          <div className="container-fluid mt-3 pb-3">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                placeholder="Enter Description"
                                className="form-control"
                                id="basic-url"
                                aria-describedby="basic-addon3"
                                defaultValue={metaData?.description}
                              />
                            </div>
                            <button
                              type="button"
                              className="btn btn-primary me-2"
                              onClick={() => notifyEditSuccess(3)}
                            >
                              Edit{" "}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => toggleDiv(3)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
}
