import { get, post } from '../index';
import SubscriptionRoutes from './Subscription.Routes';

const SubscriptionServices = {
  getStripeSecretKey: async (obj) => {
    const data = await post(SubscriptionRoutes.getStripeSecretKey, obj);
    return data;
  },
  getSubscriptionPlans: async () => {
    const data = await get(SubscriptionRoutes.getSubscriptionPlans);
    return data;
  },
  upgradePlan: async (obj) => {
    const data = await post(SubscriptionRoutes.upgradePlan, obj);
    return data;
  },
  getSetupFees: async () => {
    const data = await get(SubscriptionRoutes.getSetupFees);
    return data;
  },
  getCardDetail: async () => {
    const data = await get(SubscriptionRoutes.getCardDetail);
    return data;
  },
  updateCardDetail: async (obj) => {
    const data = await post(SubscriptionRoutes.updateCardDetail, obj);
    return data;
  },
  removeCard: async (obj)=>{
    const data = await post(SubscriptionRoutes.removeCard, obj);
    return data;
  }
}

export default SubscriptionServices