import { Tooltip } from "react-bootstrap";

export const tooltipone = (
  <Tooltip id="tooltipone">Color of the Chatbot</Tooltip>
);

export const tooltiptwo = (
  <Tooltip id="tooltiptwo">
    This is the avatar that is shown in the bot response. You can make it to a
    profile picture or your company logo
  </Tooltip>
);

export const tooltipthree = (
  <Tooltip id="tooltipthree">
    This is the background image shown in the bot conversations widget.
    <p>You can change it to a company logo or background image</p>
  </Tooltip>
);

export const tooltipfour = (
  <Tooltip id="tooltipfour">
    This is the default prompt show to the user.You can customize for your
    company and your client
  </Tooltip>
);

export const tooltipfive = (
  <Tooltip id="tooltipfour">
    These are the example question shown to guide the bot user
  </Tooltip>
);

export const tooltipsix = (
  <Tooltip id="tooltipfour">
    This is the message shown to indicate where the response came from. You can
    customise this message based on your business
  </Tooltip>
);

export const tooltipseven = (
  <Tooltip id="tooltipfour">
    This is the message shown to source label where the response came from. You
    can customise this message based on your business
  </Tooltip>
);





export const tooltipeight = (
  <Tooltip id="tooltipfour">
    This is the message shown to user which encountered an error. You can
    customise this message based on your tone, language & personality
  </Tooltip>
);

export const tooltipnine = (
  <Tooltip id="tooltipfour">
    By default we ask ChatGPT to use only your content in its response (recommneded). If you wish ChatGPT to improvise and use its own knoledgebase as well, you can select "My Content + ChatGPT"
  </Tooltip>
);


export const tooltipnineA = (
  <Tooltip id="tooltipfour">
    By default, the chatbot messages like "Ask me Anything " are in English. You can customize this to your preferred language
  </Tooltip>
);


export const tooltipten = (
  <Tooltip id="tooltipfour">
    Each chatbots response shows an option for the user to see the sources/citations from your content from which the response was generated
  </Tooltip>
);

export const tooltipeleven = (
  <Tooltip id="tooltipfour">
   Control how citations are shown . By default, the user can initiate to see the citations. You can choose to have it "Auto Shown" or "Auto Hide"
  </Tooltip>
);

export const tooltiptwelve = (
  <Tooltip id="tooltipfour">
   Control what branding is shown at the bottom of the chatbot
  </Tooltip>
);

export const tooltipthirteen = (
  <Tooltip id="tooltipfour">
   This is the message shown when the bot cannot answer. You can customize it to a message asking the user to contact customer support or leave their email /phone
  </Tooltip>
);

export const tooltipfourteen = (
  <Tooltip id="tooltipfour">
  You can instruct ChatGPT to end every response with some text like asking 
  "Please email us for further support"(Not recommneded for most use cases)
  </Tooltip>
);