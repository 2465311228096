import React, { useState } from "react";
import "../assets/css/login.scss";
import "../assets/vendor/css/rtl/core.css";
import "../assets/vendor/css/rtl/theme-default.css";
import Logo from "../assets/img/logo.svg";
import forgot from "../assets/img/illustrations/forgotpass.png";
import loginbg from "../assets/img/illustrations/bglogin.png";
import { Link, useNavigate } from "react-router-dom";
import { IconChevronLeft } from "@tabler/icons-react";
import AuthServices from "../apis/auth/AuthServices";
import { ErrorToaster, SuccessToaster } from "../components/Toaster";
import { useForm } from "react-hook-form";

export default function Login() {

  const navigate = useNavigate();

  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);

  const forgotPassword = async (formData) => {
    setLoading(true)
    try {
      let params = {
        email: formData?.email
      }
      const { message } = await AuthServices.forgotPassword(params)
      SuccessToaster(message)
      navigate('/')
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  return (

    <div class="authentication-wrapper authentication-cover authentication-bg light-style">
      <div class="authentication-inner row">
        <div className="d-none d-lg-flex col-lg-7 p-0">
          <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
            <img
              src={forgot}
              alt="auth-login-cover"
              className="img-fluid my-5 auth-illustration"
              data-app-light-img="illustrations/auth-login-illustration-light.png"
              data-app-dark-img="illustrations/auth-login-illustration-dark.png"
            />

            <img
              src={loginbg}
              alt="auth-login-cover"
              className="platform-bg "
              data-app-light-img="illustrations/bg-shape-image-light.png"
              data-app-dark-img="illustrations/bg-shape-image-dark.png"
            />
          </div>
        </div>

        <div class="d-flex col-12 col-lg-5 align-items-center p-sm-5 p-4">

          <div class="w-px-400 mx-auto">
            <div class="app-brand mb-4">
              <a href="/" class="app-brand-link gap-2">
                <span class="app-brand-logo forgot-pass">
                  <img src={Logo} />
                </span>
              </a>
            </div>
            <h3 class="mb-1 fw-bold">Forgot Password? 🔒</h3>
            <p class="mb-4">Enter your email and we'll send you instructions to reset your password</p>
            <form id="formAuthentication" class="mb-3" action="auth-reset-password-cover.html" method="POST">
              <div class="mb-3">
                {/* <label for="email" class="form-label">Email</label> */}
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  autofocus
                  {...register('email', {
                    required: 'please enter your email'
                  })}
                />
              </div>
              <button disabled={loading} class="btn btn-primary d-grid w-100" onClick={handleSubmit(forgotPassword)}>Send Reset Link</button>
            </form>
            <div class="text-center colr-forgot">
              <Link to='/' class=" d-flex align-items-center justify-content-center created" >
                <IconChevronLeft size={20} />
                Back to login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
