import React from "react";
import NewSidebar from "../components/newsidebar";
import "../assets/vendor/css/rtl/core.css";
import "../assets/vendor/css/rtl/theme-default.css";

export default function Wrapper(props) {
  return (
    <div className="min-vh-100">
      <div className="layout-page">
        <NewSidebar />
        {props.children}
      </div>
    </div>
  );
}
