import { get } from '../index';
import AddonRoutes from './Addon.Routes';

const AddonServices = {
  getAddons: async (params) => {
    const data = await get(AddonRoutes.getAddons, params);
    return data;
  },
}

export default AddonServices