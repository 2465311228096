import React, { useEffect, useState } from "react";
import { Form, Button, Accordion, AccordionItem } from "react-bootstrap";
import "../assets/css/sidebar.css";


import Wrapper from "../wrapper/wrapper";
import FaqServices from "../apis/faq/FaqServices";
import { ErrorToaster } from "../components/Toaster";

export default function Faq() {

  const [faqs, setFaqs] = useState([]);

  const getFaqs = async () => {
    try {
      const { data } = await FaqServices.getFaqs()
      setFaqs(data)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  useEffect(() => {
    getFaqs()
  }, []);

  return (
    <Wrapper>
      <div className="layout-page">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="card mt-3">
              <div className="row">
                <div className="col-sm-12">
                  <section className="py-5 pricing-faqs rounded-bottom bg-body">
                    <div className="container">
                      <div className="text-center mb-5">
                        <h2 className="mb-2">
                          <b>Frequently Asked Questions</b>
                        </h2>
                        <div class="center-div">
                          <a href="#">Getting Started</a>
                          <div class="divider"></div>
                          <a href="#">How It Works</a>
                          <div class="divider"></div>
                          <a href="#">Fix A Problem</a>
                          <div class="divider"></div>
                          <a href="#">Plan, Payments, Billing</a>
                        </div>
                        <p>
                          Let us help answer the most common questions you might
                          have.
                        </p>
                        <h1 className="font-weight-bold">
                          <b>Getting Started</b>
                        </h1>
                      </div>
                      {faqs?.length > 0 &&
                        <Accordion
                          defaultActiveKey="1"
                          flush
                          className="accordion-style"
                        >
                          {faqs?.map((item, index) => (
                            <Accordion.Item eventKey={index}>
                              <Accordion.Header>
                                {item?.question}
                              </Accordion.Header>
                              <Accordion.Body className="mt-3">
                                {item?.answer}
                              </Accordion.Body>
                            </Accordion.Item>
                          ))}

                        </Accordion>
                      }
                    </div>

                    {/* <div className="container">
                          <div className="text-center  m-4">
                            <h1 className="font-weight-bold">Fix A Problem
</h1>
                          </div>

                          <Accordion defaultActiveKey="1" flush>
                            <Accordion.Item eventKey="1">
                              <Accordion.Header>
                              What kind of support and resources does Kriss.Ai provide for customers?
                              </Accordion.Header>
                              <Accordion.Body className="mt-3">
                              Kriss.Ai provides a range of support and resources for its customers. This includes access to our online knowledge base, as well as email support from our customer service team.                              </Accordion.Body>
                            
                            
                            </Accordion.Item>

                            <Accordion.Item eventKey="2">
                              <Accordion.Header>
                                How does Kriss.Ai use my data to build a custom
                                ChatGPT-type chatbot?
                              </Accordion.Header>
                              <Accordion.Body className="mt-3">
                                Kriss.Ai uses your data to build a custom
                                chatbot by ingesting the information you provide
                                into our system. This information can include
                                your company’s public content like websites,
                                knowledge bases , helpdesks, Youtube videos, and
                                podcasts but also private content like business
                                and product information documents including PDF,
                                Microsoft Office documents, Google docs,
                                customer data, and other relevant content.
                                <p>
                                  Our secure and privacy-preserving cloud
                                  platform then ingests this content and uses
                                  this information to respond to queries with
                                  ChatGPT.
                                </p>
                                <p>
                                  The result is a Custom ChatGPT-style bot that
                                  can have a deep understanding of your business
                                  and customers, allowing it to provide
                                  personalized and accurate responses, without
                                  hallucinations, to a wide range of inquiries.
                                  This bot can then be embedded into your
                                  website, used as a LiveChat or integrated into
                                  your systems via the API.
                                </p>
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="3">
                              <Accordion.Header>
                                How do I get started with Kriss.Ai and how do I
                                subscribe to the service?
                              </Accordion.Header>
                              <Accordion.Body className="mt-3">
                                To get started with Kriss.Ai, simply sign up
                                for a subscription to our service. After the
                                2-minute setup, our system will get to work
                                building a custom chatbot trained on your
                                specific content.
                                <p>
                                  You can get started quickly just by uploading
                                  your documents. Or you can use your website
                                  sitemap. Or you can use one of our free tools.
                                  Our subscription plans are simple and
                                  flexible, so you can select the one that best
                                  fits your business requirements and budget.
                                </p>
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="4">
                              <Accordion.Header>
                                What is the pricing model for Kriss.Ai and how
                                does it work?
                              </Accordion.Header>
                              <Accordion.Body className="mt-3">
                                Kriss.Ai offers the following pricing models to
                                meet a variety of business needs.
                                <p>
                                  1. Basic ($49 /Month): 3 custom chatbots
                                  (projects); 1000 pages/chatbot; 30 million
                                  words content, 500 queries/month
                                </p>
                                <p>
                                  2. Standard ($99 /Month): 10 custom chatbots
                                  (projects); 5000 pages/chatbot; 60 million
                                  words content, 1000 queries/month
                                </p>
                                <p>
                                  3. Premium ($499 /Month): 100 custom chatbots
                                  (projects); 20,000 pages/chatbot; 300 million
                                  words content, 5000 queries/month
                                </p>
                                <p>
                                  4. Enterprise (Custom pricing): Custom
                                  parameters based on enterprise needs (requires
                                  annual commitment). We support service orders
                                  and POs.{" "}
                                </p>
                                <p>
                                  Please visit our pricing page to see the full
                                  set of features and subscription plans.
                                </p>
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="5">
                              <Accordion.Header>
                                How can I provide my content and data to
                                Kriss.Ai?
                              </Accordion.Header>
                              <Accordion.Body className="mt-3">
                                You can provide your business content and data
                                to Kriss.Ai in various ways, using the
                                Kriss.Ai interface for your project.
                                <p>
                                  You can provide the sitemap of your website or
                                  create a custom sitemap, using which Kriss.Ai
                                  can index the entire content of your website
                                  and refresh it periodically to check for any
                                  new content.
                                </p>
                                <p>
                                  You can also upload ALL your documents like
                                  PDF file, Microsoft Office documents, Google
                                  docs, etc. The platform supports 1400+
                                  document formats including audio formats and
                                  podcasts.
                                </p>
                                <p>
                                  New: You can now also mix-and-match and add
                                  data from multiple sources to create a “source
                                  of truth” with your chatbot.
                                </p>
                                <p>
                                  4. Enterprise (Custom pricing): Custom
                                  parameters based on enterprise needs (requires
                                  annual commitment). We support service orders
                                  and POs.{" "}
                                </p>
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="6">
                              <Accordion.Header>
                                Can I white-label and customize the bot to my
                                branding and business?
                              </Accordion.Header>
                              <Accordion.Body className="mt-3">
                                Yes. You can personalize your Kriss.Ai chatbot
                                to create a branded experience for your
                                customers and employees, with the desired
                                settings. See this example of a branded chatbot.
                                <p>
                                  You can customize the logo, background color
                                  or image to align with your brand’s visual
                                  identity. You can also customize the Chatbot
                                  language and default source used by the
                                  Chatbot to provide responses.
                                </p>
                                <p>
                                  New: And now, “Premium” and “Enterprise” plans
                                  can remove the “Powered by Kriss.Ai” branding
                                  for a true while-label / reseller experience.
                                </p>
                                <p>
                                  Update: And now with our new API, you can
                                  dynamically create chatbots and re-sell them
                                  to your clients.
                                </p>
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="7">
                              <Accordion.Header>
                                Can I build the bot with PDF or Microsoft Office
                                or Google Docs files I have?
                              </Accordion.Header>
                              <Accordion.Body className="mt-3">
                                Yes. You can upload your documents in 1400+
                                formats including PDFs, Microsoft Office docs,
                                Google docs.
                                <p>
                                  We also support Youtube/Vimeo videos and
                                  audio/podcast files. We support the most
                                  extensive suite of document formats in the
                                  industry.
                                </p>
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="8">
                              <Accordion.Header>
                                I'm not technical. Can I just Upload my
                                documents and build the bot?
                              </Accordion.Header>
                              <Accordion.Body className="mt-3">
                                Yes, you can simply go to your Kriss.Ai
                                dashboard, and upload the documents to build
                                your custom chatbots in minutes! Kriss.Ai is a
                                no-code ChatGPT chatbot builder requiring zero
                                technical expertise.
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="9">
                              <Accordion.Header>
                                Can I build a Chatbot for Competitive
                                Intelligence?
                              </Accordion.Header>
                              <Accordion.Body className="mt-3">
                                Yes, you can upload your competitor’s sitemap
                                and use that for competitive insights. You can
                                even upload documents or add data from multiple
                                sources to build a consolidated “source of
                                truth” on your competitor.
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="10">
                              <Accordion.Header>
                                Can Kriss.Ai read and understand tables in PDF
                                files?
                              </Accordion.Header>
                              <Accordion.Body className="mt-3">
                                Kriss.Ai does read the text from the tables (in
                                PDF and Excel/CSV files). However, do note:
                                ChatGPT (and Kriss.Ai) is quite bad at
                                analytics and math. So if your use case is
                                related to counting and analytics, Kriss.Ai
                                might not be a good choice.
                                <p>
                                  However, anything related to searching for
                                  content in the tables and then creating text
                                  responses based off that, should work
                                  remarkably well.
                                </p>
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="11">
                              <Accordion.Header>
                                Can I upload various file formats like .pdf,
                                .xls, and others for Kriss.Ai to analyze?
                              </Accordion.Header>
                              <Accordion.Body className="mt-3">
                                Yes, Kriss.Ai supports 1400 file formats
                                including PDF, Microsoft Office documents,
                                Google Docs, Text files, and Audio/Video files.
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="12">
                              <Accordion.Header>
                                How can I train Kriss.Ai with my specific
                                content or data?
                              </Accordion.Header>
                              <Accordion.Body className="mt-3">
                                Yes, you can upload specific files and sitemaps
                                with specific URLs to train your Kriss.Ai
                                chatbots.
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="13">
                              <Accordion.Header>
                                Do you have an API ?
                              </Accordion.Header>
                              <Accordion.Body className="mt-3">
                                Yes – please see :
                                https://docs.Kriss.Ai.ai/reference/quickstart-guide
                                PS: Here is an initial cookbook
                                (https://github.com/Poll-The-People/Kriss.Ai-cookbook).
                                More guides and cookbooks should be on the way
                                soon.
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="14">
                              <Accordion.Header>
                                Do you have a free trial?
                              </Accordion.Header>
                              <Accordion.Body className="mt-3">
                                Unfortunately we do not have free trials at this
                                time, but might add free trials in a couple of
                                months. Currently, the demand is very heavy and
                                we just don’t have the resources to support free
                                trials. Please feel free to check out our demo
                                at https://Kriss.Ai.ai/demo or the Livechat in
                                the bottom right of this website.
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div> */}
                  </section>
                </div>
              </div>
            </div>
          </div>

          <div class="content-backdrop fade"></div>
        </div>
      </div>
    </Wrapper>
  );
}
