import React, { useEffect, useState } from 'react'
import Wrapper from '../wrapper/wrapper'
import { ProgressBar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import BarChart from '../components/barChart'
import ChartComponent from '../components/ChartTable'
import ProfileServices from '../apis/profile/ProfileServices'
import { ErrorToaster } from '../components/Toaster'
import moment from 'moment'
import DashboardServices from '../apis/dashboard/DashboardServices'
import { useAuth } from '../context/UseContext'

export default function Usage() {

    const { user, userLogin } = useAuth();

    const [currentPlan, setCurrentPlan] = useState();
    const [stats, setStats] = useState();

    const getDashboardStats = async () => {
        try {
            let params = {
                projectId: user?.projectId
            }
            const { data } = await DashboardServices.getDashboardStats(params)
            setStats(data)
        } catch (error) {
            ErrorToaster(error)
        }
    }

    const getCurrentPlan = async () => {
        try {
            const { data } = await ProfileServices.getCurrentPlan()
            setCurrentPlan(data?.subscriptionDetails)
        } catch (error) {
            ErrorToaster(error)
        }
    }

    useEffect(() => {
        getDashboardStats()
        getCurrentPlan()
    }, []);

    return (
        <>
            <Wrapper>
                <div className="layout-page">
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h2 className="">
                                <span className="text-muted fw-bold ">Limits & Usage</span>{" "}
                            </h2>
                            <div className="row">
                                <div className="col-lg-12 col-xxl-12 mb-4 order-xxl-1">
                                    <div className='card'>
                                        <div className='card-header'>
                                            <h3>Subscription information</h3>
                                        </div>
                                        <div className="card-body d-flex flex-column gap-4">
                                            <div className="card-item text-secondary d-flex flex-row flex-wrap align-items-center justify-content-between">
                                                <div className="card-item-title">Your Plan</div>
                                                <div className="card-item-value"><span >{currentPlan?.name}, billed monthly</span></div></div>
                                            <div className="card-item text-secondary d-flex flex-row flex-wrap align-items-center justify-content-between">
                                                <div className="card-item-title">Next billing date</div><div className="card-item-value"><span >{moment(currentPlan?.updatedAt).add(1, 'month').format('DD MMMM YYYY')}</span></div></div>
                                            <div className="card-item text-secondary d-flex flex-row flex-wrap align-items-center justify-content-between">
                                                <div className="card-item-title">Usage reset date</div><div className="card-item-value"><span >{moment(currentPlan?.updatedAt).add(1, 'month').format('DD MMMM YYYY')}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <Link to="/billing-plans">
                                                <button

                                                    class="inline-flex items-center px-4 py-2 border border-transparent rounded-md font-semibold text-md text-white  tracking-widest focus:outline-none transition ease-in-out bg-custom-hex"
                                                >
                                                    {" "}
                                                    Manage Your Subscription{" "}
                                                </button>
                                            </Link>

                                        </div>
                                    </div>

                                    <div className='card' style={{ marginTop: "32px" }}>
                                        <div className='card-header'>
                                            <h3>Limits</h3>
                                        </div>
                                        <div className="card-body d-flex flex-column gap-4">
                                            <div className="card-item text-secondary d-flex flex-column  flex-sm-row flex-wrap align-items-center justify-content-between">
                                                <div className="card-item-title card-title-style">Words Indexed</div>
                                                <div className='d-flex align-items-center gap-3 flex-column  flex-sm-row'>
                                                    <div className="card-item-value"><span className='card-title-style' >90.04K of 30.00M</span></div>
                                                    <div className="card-item-value"><span><ProgressBar style={{ width: "100px" }} animated now={1} /></span></div>
                                                    <Link to="/billing-plans">
                                                        <button

                                                            class="inline-flex items-center px-4 py-2 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest focus:outline-none transition ease-in-out duration-150 bg-custom-hex bg-custom-grey"
                                                        >
                                                            Upgrade
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="card-item text-secondary d-flex flex-column  flex-sm-row flex-wrap align-items-center justify-content-between">
                                                <div className="card-item-title card-title-style">Queries (This Billing Cycle)</div>

                                                <div className='d-flex align-items-center gap-3 flex-column  flex-sm-row'>
                                                    <div className="card-item-value"><span className='card-title-style' >1 of {stats?.planQueryCount}</span></div>
                                                    <div className="card-item-value"><span ><ProgressBar style={{ width: "100px" }} animated now={1.5} /></span></div>
                                                    <Link to="/billing-plans">
                                                        <button

                                                            class="inline-flex items-center px-4 py-2 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest focus:outline-none transition ease-in-out duration-150 bg-custom-hex bg-custom-grey"
                                                        >
                                                            Upgrade
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='card' style={{ marginTop: "32px" }}>
                                        <div className='card-header'>
                                            <h3>Usage</h3>
                                        </div>
                                        <div className="card-body d-flex flex-column gap-4">
                                            <h4 style={{ paddingLeft: "50px" }}>Queries </h4>
                                            <div className='container' style={{ width: "80%" }}>
                                                <BarChart />
                                            </div>
                                            {/* <br />
                                            <h4 style={{ paddingLeft: "50px" }}>Queries </h4>
                                            <div className='container' style={{ width: "80%" }}>
                                                <ChartComponent />
                                            </div> */}
                                        </div>
                                    </div>

                                    <div className='card' style={{ marginTop: "32px" }}>
                                        <div className='card-header'>
                                            <h3>Usage Types</h3>
                                        </div>

                                        <div className="card-body custom-body d-flex flex-column ml-0">
                                            <div className='usage-data'>
                                                <div className='data-left'>Words Indexed	</div>
                                                <div className='data-right'>90.04K</div>
                                            </div>
                                            <div className='usage-data'>
                                                <div className='data-left'>Queries</div>
                                                <div className='data-right'>1</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="content-backdrop fade"></div>
                    </div>
                </div>
            </Wrapper>
        </>
    )
}
