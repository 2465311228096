import React, { useEffect, useState } from "react";

import "../assets/vendor/css/pages/front-page.css";
import Pricingpckg from "../components/pricingpckg";
import { Link } from "react-router-dom";

import img4 from "../assets/img/illustrations/girl-with-laptop.png";
import {
  IconCircleCheckFilled,
  IconCircleXFilled,
  IconStar,
} from "@tabler/icons-react";
import Wrapper from "../wrapper/wrapper";
import SubscriptionServices from "../apis/subscription/SubscriptionServices";
import { ErrorToaster } from "../components/Toaster";

export default function Pricing() {
  return (
    <Wrapper>
      <div className="layout-page">
        {/* <Navbar /> */}
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="card my-5">
              <section className="section-py pt-3">
                <div className="container">
                  <Pricingpckg />
                </div>
              </section>
            </div>

            {/* <section className="pricing-free-trial bg-label-primary">
              <div className="container">
                <div className="position-relative">
                  <div className="d-flex justify-content-between flex-column-reverse flex-lg-row align-items-center py-4 px-5">
                    <div className="text-center text-lg-start mt-2 ms-3">
                      <h3 className="text-primary mb-1">
                        Still not convinced? Start with a 14-day FREE trial!
                      </h3>
                      <p className="text-body mb-1">
                        You will get full access to with all the features for 14
                        days.
                      </p>
                      <Link to="/payment" className="btn btn-primary mt-4 mb-2">
                        Start 14-day FREE trial
                      </Link>
                    </div>
                    <div className="text-center">
                      <img
                        src={img4}
                        className="img-fluid me-lg-5 pe-lg-1 mb-3 mb-lg-0 free-trial-img"
                        alt="Api Key Image"
                        width="202"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section> */}

            {/* <section className="section-py pricing-plans-comparison">
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center mb-4">
                    <h2 className="mb-2">
                      Pick a plan that works best for you
                    </h2>
                    <p className="mb-1">
                      Stay cool, we have a 48-hour money back guarantee!
                    </p>
                  </div>
                </div>
                <div className="row mx-4">
                  <div className="col-12">
                    <div className="table-responsive table-responsive-sm border rounded">
                      <table className="table table-striped text-center mb-0">
                        <thead>
                          <tr>
                            <th scope="col">
                              <p className="mb-1">Features</p>
                              <small className="text-muted fw-normal text-capitalize">
                                Native front features
                              </small>
                            </th>
                            <th scope="col">
                              <p className="mb-1">Starter</p>
                              <small className="text-muted fw-normal text-capitalize">
                                Free
                              </small>
                            </th>
                            <th scope="col">
                              <p className="mb-1 position-relative">
                                Pro
                                <span className="badge badge-center rounded-pill bg-primary position-absolute mt-n2 ms-1">
                                  <IconStar />
                                </span>
                              </p>
                              <small className="text-muted fw-normal text-capitalize">
                                $7.5/month
                              </small>
                            </th>
                            <th scope="col">
                              <p className="mb-1">Enterprise</p>
                              <small className="text-muted fw-normal text-capitalize">
                                $16/month
                              </small>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>14-days free trial</td>
                            <td>
                              <span>
                                <IconCircleCheckFilled />
                              </span>
                            </td>
                            <td>
                              <span>
                                <IconCircleCheckFilled />
                              </span>
                            </td>
                            <td>
                              <span>
                                <IconCircleCheckFilled />
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>No user limit</td>
                            <td>
                              <span>
                                <IconCircleXFilled />
                              </span>
                            </td>
                            <td>
                              <span>
                                <IconCircleXFilled />
                              </span>
                            </td>
                            <td>
                              <span>
                                <IconCircleCheckFilled />
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Product Support</td>
                            <td>
                              <span>
                                <IconCircleXFilled />
                              </span>
                            </td>
                            <td>
                              <span>
                                <IconCircleCheckFilled />
                              </span>
                            </td>
                            <td>
                              <span>
                                <IconCircleCheckFilled />
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Email Support</td>
                            <td>
                              <span>
                                <IconCircleXFilled />
                              </span>
                            </td>
                            <td>
                              <span className="badge bg-label-primary badge-sm text-uppercase">
                                Add-on Available
                              </span>
                            </td>
                            <td>
                              <span>
                                <IconCircleCheckFilled />
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Integrations</td>
                            <td>
                              <span>
                                <IconCircleXFilled />
                              </span>
                            </td>
                            <td>
                              <span>
                                <IconCircleCheckFilled />
                              </span>
                            </td>
                            <td>
                              <span>
                                <IconCircleCheckFilled />
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Removal of Front branding</td>
                            <td>
                              <span>
                                <IconCircleXFilled />
                              </span>
                            </td>
                            <td>
                              <span className="badge bg-label-primary badge-sm text-uppercase">
                                Add-on Available
                              </span>
                            </td>
                            <td>
                              <span>
                                <IconCircleCheckFilled />
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Active maintenance & support</td>
                            <td>
                              <span>
                                <IconCircleXFilled />
                              </span>
                            </td>
                            <td>
                              <span>
                                <IconCircleXFilled />
                              </span>
                            </td>
                            <td>
                              <span>
                                <IconCircleCheckFilled />
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Data storage for 365 days</td>
                            <td>
                              <span>
                                <IconCircleXFilled />
                              </span>
                            </td>
                            <td>
                              <span>
                                <IconCircleXFilled />
                              </span>
                            </td>
                            <td>
                              <span>
                                <IconCircleCheckFilled />
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>
                              <Link
                                to="/payment"
                                className="btn text-nowrap btn-label-primary"
                              >
                                Choose Plan
                              </Link>
                            </td>
                            <td>
                              <Link
                                to="/payment"
                                className="btn text-nowrap btn-primary"
                              >
                                Choose Plan
                              </Link>
                            </td>
                            <td>
                              <Link
                                to="/payment"
                                className="btn text-nowrap btn-label-primary"
                              >
                                Choose Plan
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}

          </div>

          <div className="content-backdrop fade"></div>
        </div>
      </div>
    </Wrapper>
  );
}
