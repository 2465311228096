import React, { useEffect, useState } from 'react';
import { Table, Modal, Button } from 'antd';
import Wrapper from '../wrapper/wrapper';
import '../assets/css/planspage.css';
import DATA from '../assets/json/projectlog.json';
import { IconAlertTriangle, IconInfoCircle } from '@tabler/icons-react';

export default function EventLog() {
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        setData(DATA.data);
    }, []);

    const Types = (text, record, index) => (
        <>
            {record.status === "created" ?
                <>
                    <IconInfoCircle color='seagreen' size={20} className="m-1" />
                    <div className="badge bg-primary text-dark">
                        {record.status}
                    </div>
                </>
                :
                <>
                    {record.status === "updated" ?
                        <>
                            <IconInfoCircle color='green' size={20} className="m-1" />
                            <div className="badge bg-success text-dark">
                                {record.status}
                            </div>
                        </>
                        :
                        <>
                            <IconAlertTriangle color='red' size={20} className="m-1" />
                            <div className="badge bg-danger text-white">
                                {record.status}
                            </div>
                        </>
                    }
                </>
            }
        </>
    );


    const columns = [
        {
            title: 'Description',
            dataIndex: 'url', // Adjust according to your data fields
        },
        {
            title: 'Date',
            dataIndex: 'last_updated', // Adjust according to your data fields
        },
        {
            title: 'Type',
            key: 'status',
            render: Types,
        },
    ];

    const onRowClick = (record, rowIndex) => {
        return {
            onClick: event => {
                setSelectedRow(record);
                setIsModalVisible(true);
            },
        };
    };

    const closeModal = () => {
        setIsModalVisible(false);
        setSelectedRow(null);
    };

    return (
        <>
            <Wrapper>
                <div className="layout-page">
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h2 className="settings-header mt-6">
                                <span className="text-muted">Log / Testing5@gmail.com_project</span>
                            </h2>
                            <div className="response-table">
                                <Table
                                    columns={columns}
                                    dataSource={data}
                                    onRow={onRowClick}
                                    rowKey="id" // Make sure your data has a unique key field
                                />
                            </div>
                        </div>

                        <Modal
                            title={[
                                <div className='fw-bold'>
                                    Log Item

                                </div>
                            ]}
                            visible={isModalVisible}
                            onOk={closeModal}
                            onCancel={closeModal}
                            closable={false}
                            maskClosable={true}
                            // onCancel={closeModal}
                            footer={[
                                <Button key="submit" type="primary" className='bg-primary color-primary' onClick={closeModal}>
                                    OK
                                </Button>
                            ]}
                        >
                            {selectedRow && (
                                <>
                                    <p><strong>ID</strong><br />{selectedRow.ID}</p>
                                    <p><strong>Project</strong><br /><a href="#" target="_blank" rel="noopener noreferrer">Testing5@gmail.com_project</a></p>
                                    <p><strong>Page</strong><br /><a href="#" target="_blank" rel="noopener noreferrer">https://vmeals.ae/simple-meal-plan-to-lose-weight-fast</a></p>
                                    <p><strong>Date</strong><br />{selectedRow.last_updated}</p>
                                    <p><strong>Description</strong><br />{selectedRow.url}</p>
                                    <p><strong>Subject</strong><br />App/Models/Page</p>
                                    <p><strong>Event</strong><br />{selectedRow.status}</p>
                                    <p><strong>Properties</strong></p>
                                    <textarea
                                        readOnly
                                        placeholder='text'
                                        style={{
                                            margin:"0",
                                            width: '100%', // Set width to fill container
                                            height: '150px', // Set a fixed height
                                            fontFamily: 'monospace', // Set font to monospace for better readability
                                            border: '1px solid #000', // Add a border with light grey color
                                            borderRadius: '4px', // Optional: if you want rounded corners
                                            padding: '10px', // Add some padding inside the textarea
                                            resize: 'none', // Prevent resizing
                                            overflow: 'auto', // Add scrollbars when content overflows
                                            whiteSpace: 'pre-wrap', // Preserve formatting and wrap text
                                            backgroundColor: '#f0f0f0', // Set background color similar to the image
                                        }}
                                    />

                                </>
                            )}
                        </Modal>

                    </div>
                </div>
            </Wrapper>
        </>
    );
}
