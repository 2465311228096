import React from 'react';
import ReactApexChart from 'react-apexcharts';

const BarChart = () => {
    const options = {
        chart: {
            type: "bar",
            height: "500",
            
            background: "#F8F8F9",
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '25%',
                borderRadius: {
                    topRight: "10%",
                    topLeft: "10%",
                }
            },
        },
        colors: ['#61d9ca'],
        dataLabels: {
            enabled: true,
        },
        xaxis: {
            categories: ['16 October 2023', '21 October 2023', '23 October 2023'],
        },
        yaxis: {
            min: 0,
            max: 1,
            tickAmount: 5,

        }

    };

    const series = [
        {
            name: 'Queries',
            data: [1, 0, 0]
        }
    ];

    return (
        <ReactApexChart options={options} series={series} type="bar" height={600} />
    );
};

export default BarChart;
