import { loadStripe } from "@stripe/stripe-js";
import {
  IconArrowLeft,
  IconArrowRight,
  IconChevronRight,
  IconEye,
  IconEyeOff,
  IconFileText,
  IconSmartHome,
} from "@tabler/icons-react";
import { Form } from "antd";
import Stepper from "bs-stepper";
import React, { useEffect, useRef, useState } from "react";
import { Button, Tooltip } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AuthServices from "../apis/auth/AuthServices";
import SubscriptionServices from "../apis/subscription/SubscriptionServices";
import "../assets/css/register.css";
import img1 from "../assets/img/illustrations/auth-register-multisteps-illustration.png";
import img2 from "../assets/img/illustrations/bg-shape-image-light.png";
import Logo from "../assets/img/logo.svg";
import "../assets/vendor/css/pages/page-auth.css";
import "../assets/vendor/css/rtl/core.css";
import "../assets/vendor/css/rtl/theme-default.css";
import { ErrorToaster, SuccessToaster } from "../components/Toaster";
import { useAuth } from "../context/UseContext";
import { emailRegex, stripeKey } from "../utils";
import CountryServices from "../apis/country/CountryServices";
import { CardElement, CardCvcElement, CardExpiryElement, CardNumberElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';

const StripePaymentForm = ({
  selectedPlan,
  email,
  handlePrevious,
  submitRegister,
  couponCode,
  name_of_practice,
  handleRegistringLoader
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);

  const handlePayment = async (e) => {
    setIsLoading(true);
    handleRegistringLoader()
    await submitRegister();
    setIsLoading(false);
    handleRegistringLoader()
  };

  return (
    <>
      {/* <CardElement /> */}
      <div className="col-12 d-flex justify-content-between mt-4">
        <Button
          className="btn btn-label-secondary btn-prev"
          onClick={() => handlePrevious()}
        >
          <IconArrowLeft className="me-sm-1 me-0" />
          <span className="align-middle d-sm-inline-block d-none">
            Previous
          </span>
        </Button>
        <Button
          className="btn btn-success btn-next btn-submit"
          onClick={() => handlePayment()}
          type={"button"}
          disabled={isLoading}
        >
          <span className="align-middle d-sm-inline-block d-none">Submit </span>
        </Button>
      </div>
    </>
  );
};

export default function Register() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const stepperRef = useRef(null);

  const [selectedPlan, setSelectedPlan] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmpass, setShowConfirmpass] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
  } = useForm();
  const password = useRef({});
  password.current = watch("password", "");

  const stripePromise = loadStripe(stripeKey);

  const { userLogin } = useAuth();

  const [step1Data, setStep1Data] = useState();
  const [step2Data, setStep2Data] = useState();
  const [emailExists, setEmailExists] = useState(false);
  const [isRegistring, setIsRegistring] = useState(false);
  
  const [couponCode, setCouponCode] = useState('');
  const [signupCode, setSignupCode] = useState('');
  const [discount, setDiscount] = useState(0);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    appearance,
  };

  const tooltipone = (
    <Tooltip id="tooltipfour">Card Verification Value</Tooltip>
  );

  useEffect(() => {
    if (!stepperRef.current) {
      stepperRef.current = new Stepper(document.querySelector("#stepper1"), {
        linear: false,
        animation: false,
      });
    }
    return () => {
      if (stepperRef.current) {
        stepperRef.current.destroy();
        stepperRef.current = null;
      }
    };
  }, []);

  const handleNext1 = async (data) => {
    const { status } = await AuthServices.checkExists({
      email: data.email
    })
    setEmailExists(status)
    if(!status){
      setStep1Data(data);
      if (stepperRef.current) {
        stepperRef.current.next();
      }
    }else{

    }
  };

  const handleNext2 = (data) => {
    setStep2Data(data);
    if (stepperRef.current) {
      stepperRef.current.next();
    }
  };

  const handlePrevious = () => {
    if (stepperRef.current) {
      stepperRef.current.previous();
    }
  };

  const [plans, setPlans] = useState([]);

  const getSubscriptionPlans = async () => {
    try {
      const { data } = await SubscriptionServices.getSubscriptionPlans()
      setPlans(data)
      setSelectedPlan(data[0]?.id);
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const applyDiscount = async ()=>{
    setDiscount(0)
    const couponCheck = {
      code: couponCode
    }
    const { data } = await AuthServices.checkCoupon(couponCheck);
    if(data){
      if(data.isActive){
        let priceTotal = 0;
        if(data.allowforfees){
          priceTotal+=parseFloat(plans.find(e => e.id === selectedPlan)?.price)
        }
        if(data.allowforsetup){
          priceTotal+=parseFloat(plans.find(e => e.id === selectedPlan)?.setupFees)
        }
        setDiscount(((parseFloat(priceTotal)/100)*data.discount_percent))
      }
    }
  }
  useEffect(()=>{
    applyDiscount()
  },[selectedPlan])

  const submitRegister = async () => {
    try {
      let obj = {
        name: step1Data?.name,
        name_of_practice: step1Data?.name_of_practice,
        name_prefix: step1Data?.name_prefix,
        aname_prefix: step1Data?.aname_prefix,
        aname: step1Data?.aname,
        email: step1Data?.email,
        password: step1Data?.password,
        cpassword: step1Data?.confirmPassword,
        signupCode: signupCode,//step1Data?.signupCode,
        country: plans[0]?.country,
        subscriptionPlanId: selectedPlan,
      };
      const { message, token, user, stripeSession } = await AuthServices.register(obj);
      SuccessToaster(message);
      // navigate("/payment-success");
      userLogin({ token: token, ...user });
      window.location.href=stripeSession.url
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const handleRegistringLoader = async () => {
    setIsRegistring(!isRegistring)
  }
  useEffect(() => {
    getSubscriptionPlans()
  }, [])

  return (
    <div className="authentication-wrapper authentication-cover authentication-bg">
      <div className="authentication-inner row">
        {
          isRegistring&&
          <div className="loading-register">
            <div>
              <div className="loading-register-card">
                <img
                  src={Logo}
                  alt="auth-register-multisteps"
                  className="img-fluid"
                  width="280"
                />
                <p>Verifying Registration Details</p>
                <div className="loading-anim"></div>
              </div>
            </div>
          </div>
        }
        {/* <div className="d-none d-lg-flex col-lg-4 align-items-center justify-content-center p-5 auth-cover-bg-color position-relative auth-multisteps-bg-height">
          <img
            src={img1}
            alt="auth-register-multisteps"
            className="img-fluid"
            width="280"
          />

          <img
            src={img2}
            alt="auth-register-multisteps"
            className="platform-bg"
            data-app-light-img="illustrations/bg-shape-image-light.png"
          />
        </div> */}

        <div className="d-flex col-lg-12 align-items-center justify-content-center p-sm-5 p-3">
          <div className="w-px-700">
            <div className="register-logo">
              <a href="/" class="">
                <span class="app-brand-logo demo">
                  <img src={Logo} className="login-logo" />
                </span>
              </a>
            </div>
            <div id="stepper1" className="bs-stepper shadow-none">
              <div className="bs-stepper-header border-bottom-0">
                <div className="step" data-target="#test-l-1">
                  <button type="button" className="step-trigger">
                    <span className="bs-stepper-circle">
                      <IconSmartHome />
                    </span>
                    <span className="bs-stepper-label">
                      <span className="bs-stepper-title">Account</span>
                      <span className="bs-stepper-subtitle">
                        Account Details
                      </span>
                    </span>
                  </button>
                </div>
                {/* <div className="line">
                  <IconChevronRight size={16} />
                </div> */}
                {/* <div className="step" data-target="#test-l-2">
                  <button type="button" className="step-trigger">
                    <span className="bs-stepper-circle">
                      <IconUser />
                    </span>
                    <span className="bs-stepper-label">
                      <span className="bs-stepper-title">Business</span>
                      <span className="bs-stepper-subtitle">
                        Enter Information
                      </span>
                    </span>
                  </button>
                </div> */}
                <div className="line">
                  <IconChevronRight size={16} />
                </div>
                <div className="step" data-target="#test-l-3">
                  <button type="button" className="step-trigger">
                    <span className="bs-stepper-circle">
                      <IconFileText />
                    </span>
                    <span className="bs-stepper-label">
                      <span className="bs-stepper-title">Billing</span>
                      <span className="bs-stepper-subtitle">
                        Select Plan
                      </span>
                    </span>
                  </button>
                </div>
              </div>

              <div className="bs-stepper-content">
                <Form form={form} name="basic">
                  <div id="test-l-1" className="content">
                    <div className="content-header mb-4">
                      <h3 className="mb-1">Account Information</h3>
                      <p>Enter Your Account Details</p>
                    </div>
                    <div className="row g-3">
                      <div className="col-sm-6">
                        <label className="form-label" for="multiStepspractive">
                        Name of Practice
                        </label>
                        <input
                          placeholder="Idea Dental"
                          type="text"
                          name="multiStepspractive"
                          id="multiStepspractive"
                          className="form-control"
                          {...register("name_of_practice", {
                            required: "Please enter Name of Practice",
                          })}
                        />
                        {errors && (
                          <p className={"error"}>{errors?.name_of_practice?.message}</p>
                        )}
                      </div>
                      <div className="col-sm-6">
                        <label className="form-label" for="multiStepspractive">
                          Name of Primary
                        </label>
                        <div className="d-flex gap-2">
                          <select {...register("name_prefix", {
                            })} className="form-control w-20">
                            <option value={'Mr.'}>Mr.</option>
                            <option value={'Mrs.'}>Mrs.</option>
                            <option value={'Ms.'}>Ms.</option>
                            <option value={'Dr.'}>Dr.</option>
                          </select>
                          <input
                            placeholder="John Doe"
                            type="text"
                            name="multiStepspractive"
                            id="multiStepspractive"
                            className="form-control"
                            {...register("name", {
                              required: "Please enter Name of Primary",
                            })}
                          />
                        </div>
                        {errors && (
                          <p className={"error"}>{errors?.name?.message}</p>
                        )}
                      </div>
                      <div className="col-sm-6">
                        <label className="form-label" for="multiAccountManagerName">
                          Account Manager Name
                        </label>
                        <div className="d-flex gap-2">
                          <select {...register("aname_prefix", {
                            })} className="form-control w-20">
                            <option value={'Mr.'}>Mr.</option>
                            <option value={'Mrs.'}>Mrs.</option>
                            <option value={'Ms.'}>Ms.</option>
                            <option value={'Dr.'}>Dr.</option>
                          </select>
                          <input
                            placeholder="John Doe"
                            type="text"
                            name="multiAccountManagerName"
                            id="multiAccountManagerName"
                            className="form-control"
                            {...register("aname", {
                              required: "Please enter Name of Account Manager",
                            })}
                          />
                        </div>
                        {errors && (
                          <p className={"error"}>{errors?.aname?.message}</p>
                        )}
                      </div>
                      <div className="col-sm-6">
                        <label className="form-label" for="multiStepsEmail">
                          Email
                        </label>
                        <input
                          type="email"
                          name="multiStepsEmail"
                          id="multiStepsEmail"
                          className="form-control"
                          placeholder="example@email.com"
                          aria-label="john.doe"
                          {...register("email", {
                            required: "Please enter email",
                            pattern: {
                              value: emailRegex,
                              message: "Please enter a valid email.",
                            },
                          })}
                        />
                        {errors && (
                          <p className={"error"}>{errors?.email?.message}</p>
                        )}
                        {emailExists && (
                          <p className={"error"}>Email already taken</p>
                        )}
                      </div>
                      <div className="col-sm-6 form-password-toggle">
                        <label className="form-label" for="multiStepsPass">
                          Password
                        </label>
                        <div className="input-group input-group-merge">
                          <input
                            className="form-control"
                            name="currentPassword"
                            id="currentPassword"
                            type={`${!showPassword ? "password" : "text"}`}
                            placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                            {...register("password", {
                              required: "Please enter password",
                              minLength: {
                                value: 6,
                                message: "Password should be atleast 6 characters",
                              },
                            })}
                          />
                          <span className="input-group-text cursor-pointer">
                            {showPassword ? (
                              <IconEye onClick={() => setShowPassword(false)} />
                            ) : (
                              <IconEyeOff
                                onClick={() => setShowPassword(true)}
                              />
                            )}
                          </span>
                        </div>
                        {errors && (
                          <p className={"error"}>{errors?.password?.message}</p>
                        )}
                      </div>
                      <div className="col-sm-6 form-password-toggle">
                        <label
                          className="form-label"
                          for="multiStepsConfirmPass"
                        >
                          Confirm Password
                        </label>
                        <div className="input-group input-group-merge">
                          <input
                            className="form-control"
                            type={`${!showConfirmpass ? "password" : "text"}`}
                            name="confirmPassword"
                            id="confirmPassword"
                            placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                            {...register("confirmPassword", {
                              required: "Please enter the confirm password",
                              validate: (value) =>
                                value === password.current ||
                                "Confirm password does not match.",
                            })}
                          />
                          <span className=" input-group-text cursor-pointer">
                            {showConfirmpass ? (
                              <IconEye
                                onClick={() => setShowConfirmpass(false)}
                              />
                            ) : (
                              <IconEyeOff
                                onClick={() => setShowConfirmpass(true)}
                              />
                            )}
                          </span>
                        </div>
                        {errors && (
                          <p className={"error"}>
                            {errors?.confirmPassword?.message}
                          </p>
                        )}
                      </div>
                      <div className="col-md-12">
                        <label className="form-label" for="multiStepsAddress">
                        Rep Code
                        </label>
                        <input
                          type="text"
                          id="multiStepsAddress"
                          name="multiStepsAddress"
                          className="form-control"
                          value={signupCode}
                          placeholder="Rep Code"
                          onChange={e => setSignupCode(e.target.value)}
                        />
                      </div>

                      <div className="col-12 d-flex justify-content-between mt-4">
                        <button
                          className="btn btn-label-secondary btn-prev"
                          disabled
                        >
                          <i className="ti ti-arrow-left ti-xs me-sm-1 me-0"></i>
                          <span className="align-middle d-sm-inline-block d-none">
                            Previous
                          </span>
                        </button>
                        <Button
                          type={"button"}
                          className="btn btn-primary btn-next"
                          onClick={handleSubmit(handleNext1)}
                        >
                          <span className="align-middle d-sm-inline-block d-none me-sm-1 me-0">
                            Next
                          </span>
                          <IconArrowRight />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div id="test-l-3" className="content">
                    <div className="content-header">
                      <h3 className="mb-1">Select a Plan</h3>
                      {/* <p>Select plan as per your requirement</p> */}
                    </div>
                    {plans.length > 0 && (
                      <div className="d-plan-gridding">
                        {plans.map((item, index) => (
                          <div key={index} className="col-md" onClick={() => setSelectedPlan(item?.id)}>
                            <div
                              className={`h-100 d-flex flex-column align-items-center form-check custom-option custom-option-icon ${selectedPlan === item?.id && "active-plan"
                                }`}
                            >
                              <div>
                                <h2 className="custom-option-title">
                                  {item?.name}
                                </h2>
                                {
                                  item?.features?.keyfeatures&&<ul className="plan-features">
                                    {item?.features?.keyfeatures.map((f)=>{
                                      return (<li className="mb-0 fs-6">
                                        {f.trim()}
                                      </li>)
                                    })}
                                  </ul>
                                }
                              </div>
                              <div className="custom-option-body">
                                <span className="pricing-show d-flex justify-content-center align-items-center">
                                  <sup className="text-primary fs-6 lh-1 mt-3">
                                    $
                                  </sup>
                                  <span className="fw-medium fs-2 text-primary">
                                    {parseFloat(item?.price).toFixed(0)}
                                  </span>
                                  <sub className="lh-1 fs-6 text-muted">
                                     /month
                                  </sub>
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}

                    <div className="plan-total-show">
                      <h2>Setup <span>${parseFloat(plans.find(e => e.id === selectedPlan)?.setupFees)}</span></h2>
                      <h2>Subscription <span>${parseFloat(plans.find(e => e.id === selectedPlan)?.price)}</span></h2>
                      {discount>0&&<h2>Discount <span>-${parseFloat(discount).toFixed(2)}</span></h2>}
                      {discount==0&&<h2 className="total-plan-h2">Total <span>${parseFloat(plans.find(e => e.id === selectedPlan)?.price) + parseFloat(plans.find(e => e.id === selectedPlan)?.setupFees)}</span></h2>}
                      {discount>0&&<h2 className="total-plan-h2">Total <span>${((parseFloat(plans.find(e => e.id === selectedPlan)?.price) + parseFloat(plans.find(e => e.id === selectedPlan)?.setupFees))-discount)}</span></h2>}
                    </div>
                    <Elements options={options} stripe={stripePromise}>
                      <StripePaymentForm
                        selectedPlan={selectedPlan}
                        email={step1Data?.email}
                        couponCode={couponCode}
                        handlePrevious={() => handlePrevious()}
                        submitRegister={() => submitRegister()}
                        handleRegistringLoader={()=>{handleRegistringLoader()}}
                        name_of_practice={step1Data?.name_of_practice}
                      />
                    </Elements>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
