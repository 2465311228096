import { get, post } from '../index';
import ProfileRoutes from './Profile.Routes';

const ProfileServices = {
  getProfileDetails: async (params) => {
    const data = await get(ProfileRoutes.getProfileDetails, params);
    return data;
  },
  updateProfile: async (obj) => {
    const data = await post(ProfileRoutes.updateProfile, obj);
    return data;
  },
  resetPassword: async (obj) => {
    const data = await post(ProfileRoutes.resetPassword, obj);
    return data;
  },
  deactivateAccount: async (obj) => {
    const data = await post(ProfileRoutes.deactivateAccount, obj);
    return data;
  },
  uploadProfilePicture: async (obj) => {
    const data = await post(ProfileRoutes.uploadProfilePicture, obj);
    return data;
  },
  accountSetup: async (obj) => {
    const data = await post(ProfileRoutes.accountSetup, obj);
    return data;
  },
  getAccountSetup: async () => {
    const data = await get(ProfileRoutes.getAccountSetup);
    return data;
  },
  getCurrentPlan: async (obj) => {
    const data = await get(ProfileRoutes.getCurrentPlan, obj);
    return data;
  },
  cancelSubscription: async (obj) => {
    const data = await post(ProfileRoutes.cancelSubscription, obj);
    return data;
  },
  getPaymentHistory: async (params) => {
    const data = await get(ProfileRoutes.getPaymentHistory, params);
    return data;
  },
}

export default ProfileServices