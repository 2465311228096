import React from "react";
import "../assets/vendor/css/rtl/core.css";
import "../assets/css/login.scss";
import "../assets/vendor/css/rtl/theme-default.css";
import Wrapper from "../wrapper/wrapper";
import { Link } from "react-router-dom";

export default function Onboardhome() {

  return (
    <Wrapper>
      <div className="layout-container">
        <div className="layout-page">
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <div className="card card-action mb-5 mt-4">
                <div className="jumbotron rounded">
                  <div className="container text-center setup-style">
                    <p className="lead">
                      Hello Doctor and Welcome to Kriss AI!
                      <br />
                      To better assist you, we will be training Kriss on your
                      office and your preferences so she communicates with
                      patients the way you would like her to.
                    </p>

                    <p className="lead">
                      Please take a few minutes to answer the following
                      questions and we will review them with you during the
                      onboarding process. We promise, it's a lot quicker than
                      training a new employee.
                    </p>
                    <Link to='/onboard-two'>
                      <button className="setup mt-5 mb-3 text-center">
                        Setup Account
                      </button>
                    </Link>

                  </div>
                </div>
              </div>
            </div>

            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
