import React from "react";
import Account from "./components/account";
import Planpage from "./components/plansPage";
import AddsOn from "./pages/addsOn";
import Chat from "./pages/chat";
import Faq from "./pages/faq";
import { BrowserRouter as Router, Route, Routes, Outlet, Navigate } from "react-router-dom";
import Login from "./pages/login";
import Register from "./pages/register";
import ForgotPassword from "./pages/forgotpass";
import SettingNew from "./pages/settingsNew";
import Dashboard from "./pages/dashboard";
import Pricing from "./pages/pricing";
import Payment from "./pages/payment";
import Personal from "./pages/personal";
import Contact from "./pages/contact";
import Onboard from "./pages/onboard_home";
import OnboardLast from "./pages/onboardlast";
import Onboardtwo from "./pages/onboardtwo";
import PageList from "./pages/pagelist";
import Settings from "./pages/settings";
import EventLog from "./pages/eventLog"
import PaymentSuccess from "./pages/paymentSuccess"
import MetaData from "./components/metadata"
// admin routes
import Usage from "./pages/usage";
import { useAuth } from "./context/UseContext";
import { ToasterContainer } from "./components/Toaster";


export default function App() {

  const { user } = useAuth();

  return (
    <Router>

      <ToasterContainer />

      <Routes>

        {/* user screens */}
        <Route element={user?.token ?  <Navigate to="/dashboard" />  : <Outlet />} >
          <Route path="/" exact element={<Login />} />
          <Route path="/register" exact element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Route>

        {/* main pages */}
        <Route element={user?.token ? <Outlet /> : <Navigate to="/" />} >
          <Route path="/pricing" element={<Pricing name={'Pricing'} />} />
          <Route path="/faq" element={<Faq name={'Faq'} />} />
          <Route path="/contact-us" element={<Contact name={'Contact'} />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/payment" element={<Payment name={'Payment'} />} />
          <Route path="/personal" element={<Personal name={'Chatbot Settings'} />} />
          <Route path="/dashboard" element={<Dashboard name={'Dashboard'} />} />
          <Route path="/onboard" element={<Onboard />} />
          <Route path="/onboard-two" element={<Onboardtwo />} />
          <Route path="/onboard-thanks" element={<OnboardLast />} />
          <Route path="/account" element={<Account />} />
          <Route path="/billing-plans" element={<Pricing name={'Pricing'} />} />
          <Route path="/pages" element={<PageList />} />
          <Route path="/adds" element={<AddsOn name={'Add On'} />} />
          <Route path="/settings" element={<SettingNew />} />
          <Route path="/usage" element={<Usage />} />
          <Route path="/event-log" element={<EventLog />} />
          <Route path="/metadata/:id" element={<MetaData />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />

        </Route>

      </Routes>
    </Router>
  );
}
