import { get } from '../index';
import FaqRoutes from './Faq.Routes';

const FaqServices = {
  getFaqs: async (params) => {
    const data = await get(FaqRoutes.getFaqs, params);
    return data;
  },
}

export default FaqServices