import React, { useEffect, useState } from "react";
import "../assets/vendor/css/rtl/core.css";
import "../assets/vendor/css/rtl/theme-default.css";
import mastercard from "../assets/img/icons/payments/mastercard.png";
import visacard from "../assets/img/icons/payments/visa.png";
import Pricingpckg from "../components/pricingpckg";
import Wrapper from "../wrapper/wrapper";
import { useLocation, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import SubscriptionServices from "../apis/subscription/SubscriptionServices";
import { ErrorToaster, SuccessToaster } from "../components/Toaster";
import { emailRegex, stripeKey } from "../utils";
import { useForm } from "react-hook-form";
import { useAuth } from "../context/UseContext";
import CountryServices from "../apis/country/CountryServices";
import Swal from "sweetalert2";

export default function Payment(props) {

  const { state } = useLocation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const [isChecked, setIsChecked] = useState(false);
  const [checkedLabel, setCheckedLabel] = useState("label1");
  const [countries, setCountries] = useState([]);

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const handleCheck = (label) => {
    setCheckedLabel((prevLabel) => (prevLabel === label ? "" : label));
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [cardDetail, setCardDetail] = useState();

  const proceedPayment = async () => {
    setIsLoading(true);
    try {
      if (!state?.isAddOn) {
        let planObj = {
          subscriptionPlanId: state?.id
        }
        const { message } = await SubscriptionServices.upgradePlan(planObj)
        SuccessToaster(message)
      }
      navigate('/payment-success')
    } catch (error) {
      Swal.fire("Error upgrade plan", "", "error");
      console.log(error)
    } finally {
      setIsLoading(false);
    }
  }

  const getCountriesByPlan = async () => {
    try {
      const { data } = await CountryServices.getCountriesByPlan()
      setCountries(data)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  const getCardDetail = async () => {
    try {
      const { result } = await SubscriptionServices.getCardDetail();
      setCardDetail(result);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  useEffect(() => {
    getCountriesByPlan()
    getCardDetail()
  }, []);

  return (
    <Wrapper>
      <div className="layout-page">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="py-3 mb-4 fw-bold">{props.name}</h4>
            <section className="bg-body">
              <div className="container mt-2">
                <div className="card px-3">
                  <div className="row">
                    <div className="col-lg-7 card-body border-end">
                      <h4 className="mb-2">Checkout</h4>
                      {/* <p className="mb-0">
                        All plans include 40+ advanced tools and features to
                        boost your product. <br />
                        Choose the best plan to fit your needs.
                      </p> */}
                      <div className="row py-4 my-2">
                        {
                          !cardDetail?.cardDetails?.brand&&<h4 className="bg-danger text-white rounded p-3">No Payment Method Attached</h4>
                        }
                        {
                          cardDetail?.cardDetails?.brand&&
                          <div className="col-md mb-md-0 mb-2">
                            <div
                              className={`form-check custom-option custom-option-basic ${checkedLabel === "label1" && "checked"
                                }`}
                            >
                              <label
                                className="form-check-label custom-option-content form-check-input-payment d-flex gap-3 align-items-center"
                                for="customRadioVisa"
                              >
                                <input
                                  name="customRadioTemp"
                                  className="form-check-input"
                                  type="radio"
                                  value="credit-card"
                                  id="customRadioVisa"
                                  checked={checkedLabel === "label1"}
                                />
                                <span className="custom-option-body">
                                  <img
                                    src={cardDetail?.cardDetails?.brand === 'mastercard' ? mastercard : visacard}
                                    alt="visa-card"
                                    width="58"
                                    data-app-light-img="icons/payments/visa-light.png"
                                    data-app-dark-img="icons/payments/visa-dark.png"
                                  />
                                  <span className="ms-3">
                                    &#8727;&#8727;&#8727;&#8727;
                                    &#8727;&#8727;&#8727;&#8727; {cardDetail?.cardDetails?.last4}
                                  </span>
                                </span>

                              </label>
                            </div>
                          </div>
                        }

                        {/* <div className="col-md mb-md-0 mb-2">
                          <div
                            className={`form-check custom-option custom-option-basic ${checkedLabel === "label2" && "checked"
                              }`}
                          >
                            <label
                              className="form-check-label custom-option-content form-check-input-payment d-flex gap-3 align-items-center"
                              for="customRadioPaypal"
                              onClick={handleToggle}
                            >
                              <input
                                name="customRadioTemp"
                                className="form-check-input"
                                type="radio"
                                value="paypal"
                                id="customRadioPaypal"
                                checked={checkedLabel === "label2"}
                                onChange={() => handleCheck("label2")}
                              />
                              <span className="custom-option-body">
                                <img
                                  src={pay2}
                                  alt="paypal"
                                  width="58"
                                  data-app-light-img="icons/payments/paypal-light.png"
                                  data-app-dark-img="icons/payments/paypal-dark.png"
                                />
                                <span className="ms-3">Paypal</span>
                              </span>
                            </label>
                          </div>
                        </div> */}
                      </div>
                      <h4 className="mt-2 mb-4">Billing Details</h4>
                      <form>
                        <div className="row g-3">
                          <div className="col-md-12">
                            <label className="form-label" for="billings-email">
                              Email Address
                            </label>
                            <input
                              disabled
                              type="text"
                              id="billings-email"
                              className="form-control"
                              placeholder="email@gmail.com"
                              value={user?.email}
                            // {...register('email', {
                            //   required: 'Please enter email',
                            //   pattern: {
                            //     value: emailRegex,
                            //     message: 'Please enter a valid email.',
                            //   },
                            //   // onChange: e => setEmail(e.target.value)
                            // })}
                            />
                            {errors &&
                              <p className={'error'}>{errors?.email?.message}</p>
                            }
                          </div>
                          {/* <div className="col-md-6">
                            <label
                              className="form-label"
                              for="billings-password"
                            >
                              Password
                            </label>
                            <input
                              type="password"
                              id="billings-password"
                              className="form-control"
                              placeholder="Password"
                            />
                          </div> */}
                          {/* <div className="col-md-6">
                            <label
                              className="form-label"
                              for="billings-country"
                            >
                              Country
                            </label>
                            <select
                              id="billings-country"
                              className="form-select"
                              data-allow-clear="true"
                              {...register("country")}
                            >
                              <option value="">Select Country</option>
                              {countries?.map((item, index) => (
                                <option key={index} value={item?.name}>{item?.name}</option>
                              ))}
                            </select>
                          </div> */}
                          {/* <div className="col-md-6">
                            <label className="form-label" for="billings-zip">
                              Billing Zip / Postal Code
                            </label>
                            <input
                              disabled
                              type="text"
                              id="billings-zip"
                              className="form-control billings-zip-code"
                              placeholder="Zip / Postal Code"
                              value={user?.zipCode}
                            />
                          </div> */}
                        </div>
                      </form>

                      {checkedLabel === "label1" && (
                        <div id="form-credit-card">
                          {/* <h4 className="mt-4 pt-2">Credit Card Info</h4> */}
                          {/* <Elements options={options} stripe={stripePromise}>
                            <StripePaymentForm
                              isAddOn={state?.isAddOn}
                              email={user?.email}
                              planId={state?.id}
                              setIsLoading={(v) => setIsLoading(v)}
                            />
                          </Elements> */}
                          {/* <form>
                            <div className="row g-3">
                              <div className="col-12">
                                <label
                                  className="form-label"
                                  for="billings-card-num"
                                >
                                  Card number
                                </label>
                                <div className="input-group input-group-merge">
                                  <input
                                    type="text"
                                    id="billings-card-num"
                                    className="form-control billing-card-mask"
                                    placeholder="7465 8374 5837 5067"
                                    aria-describedby="paymentCard"
                                  />
                                  <span
                                    className="input-group-text cursor-pointer p-1"
                                    id="paymentCard"
                                  >
                                    <span className="card-type"></span>
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <label
                                  className="form-label"
                                  for="billings-card-name"
                                >
                                  Name
                                </label>
                                <input
                                  type="text"
                                  id="billings-card-name"
                                  className="form-control"
                                  placeholder="John Doe"
                                />
                              </div>
                              <div className="col-md-3">
                                <label
                                  className="form-label"
                                  for="billings-card-date"
                                >
                                  EXP. Date
                                </label>
                                <input
                                  type="text"
                                  id="billings-card-date"
                                  className="form-control billing-expiry-date-mask"
                                  placeholder="MM/YY"
                                />
                              </div>
                              <div className="col-md-3">
                                <label
                                  className="form-label"
                                  for="billings-card-cvv"
                                >
                                  CVV
                                </label>
                                <input
                                  type="text"
                                  id="billings-card-cvv"
                                  className="form-control billing-cvv-mask"
                                  maxlength="3"
                                  placeholder="965"
                                />
                              </div>
                            </div>
                          </form> */}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-5 card-body">
                      <h4 className="mb-2">Order Summary</h4>
                      <p className="pb-2 mb-0">
                        It can help you manage and service orders before,
                        <br />
                        during and after fulfilment.
                      </p>
                      <div className="bg-lighter p-4 rounded mt-4">
                        <p className="mb-1">{state?.name}</p>
                        <div className="d-flex align-items-center">
                          <h1 className="text-heading display-5 mb-1">
                            ${state?.price}
                          </h1>
                          <sub>/month</sub>
                        </div>
                        {/* <div className="d-grid">
                          <button
                            type="button"
                            data-bs-target="#pricingModal"
                            data-bs-toggle="modal"
                            class="btn btn-label-primary plan-btn"
                          >
                            Change Plan
                          </button>
                        </div> */}
                      </div>

                      {/* <div class="mt-3">

                        <div class="bg-white sm:rounded-lg shadow-sm">

                          <div class="px-6 py-4 ">
                            <h4 className="text-heading mb-1">
                              Apply Coupon
                            </h4>
                            <div class="mt-6 flex">
                              <label
                                for="coupon_for_existing"
                                class="w-1/3 mr-10 mt-2 text-gray-800 text-sm font-semibold"
                              >
                                Coupon
                              </label>
                              <input
                                type="text"
                                id="coupon_for_existing"
                                placeholder="Coupon"
                                class="w-full bg-white border border-gray-200 px-3 py-2 rounded outline-none"
                              />
                            </div>
                          </div>
                          <div class="px-6 py-4 mt-5 bg-gray-100 bg-opacity-50 border-t border-gray-100">
                            <button class="w-100 inline-flex justify-content-center px-4 py-2 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest focus:outline-none transition ease-in-out duration-150 bg-custom-hex">
                              {" "}
                              Apply Coupon{" "}
                            </button>
                          </div>
                        </div>
                      </div> */}
                      <div>
                        {/* <div className="d-flex justify-content-between align-items-center mt-3">
                          <p className="mb-0">Subtotal</p>
                          <h6 className="mb-0">${state?.price}</h6>
                        </div> */}
                        {/* <div className="d-flex justify-content-between align-items-center mt-3">
                          <p className="mb-0">Tax</p>
                          <h6 className="mb-0">$4.99</h6>
                        </div> */}
                        {/* <hr /> */}
                        <div className="d-flex justify-content-between align-items-center mt-3 pb-1">
                          <p className="mb-0">Total</p>
                          <h6 className="mb-0">${state?.price}</h6>
                        </div>
                        <div className="d-grid mt-3">
                          {
                            cardDetail?.cardDetails?.brand&&
                            <button disabled={isLoading} className="btn btn-success" onClick={handleSubmit(proceedPayment)}>
                              <span className="me-2">Proceed with Payment</span>
                              <i className="ti ti-arrow-right scaleX-n1-rtl"></i>
                            </button>
                          }
                          {
                            !cardDetail?.cardDetails?.brand&&
                            <button onClick={()=>{navigate('/billing-plans')}} className="btn btn-success">
                              <span className="me-2">Attach a Card to Proceed</span>
                              <i className="ti ti-arrow-right scaleX-n1-rtl"></i>
                            </button>
                          }
                        </div>

                        <p className="mt-4 pt-2">
                          By continuing, you accept to our Terms of Services and
                          Privacy Policy. Please note that payments are
                          non-refundable.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          {/* modal */}
          <div
            className="modal fade"
            id="pricingModal"
            tabindex="-1"
            aria-hidden="true"
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
          >
            <div className="modal-dialog modal-xl modal-simple modal-pricing ">
              <div className="modal-content p-2 p-md-5">
                <div className="modal-body">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                  <div className="py-0 rounded-top">
                    <Pricingpckg />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* modal */}

          <div className="content-backdrop fade"></div>
        </div>
      </div>
    </Wrapper>
  );
}
