import {
  IconArrowRight,
  IconChevronRight,
  IconUser,
} from "@tabler/icons-react";
import Stepper from "bs-stepper";
import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import ProfileServices from "../apis/profile/ProfileServices";
import "../assets/css/user.css";
import "../assets/vendor/css/rtl/core.css";
import "../assets/vendor/css/rtl/theme-default.css";
import CustomSlider from "../components/CustomSlider";
import CustomSlidermin from "../components/CustomSlidermin";
import TimersPick from "../components/TimersPick";
import { ErrorToaster, SuccessToaster } from "../components/Toaster";
import Wrapper from "../wrapper/wrapper";
import Insurance from "../assets/json/insurance.json";
import CountryServices from "../apis/country/CountryServices";

export default function Onboardtwo() {

  const dentalRef = useRef(null);
  const dentalRef2 = useRef(null);
  const navigate = useNavigate();

  const { register, handleSubmit, formState: { error }, setValue } = useForm();
  const [loading, setLoading] = useState(false);

  const [sliderValue, setSliderValue] = useState(0);
  const [slider2Value, setSlider2Value] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [operationHours, setOperationHours] = useState([
    { day: "Monday", from: new Date(), to: new Date(), isOn: true },
    { day: "Tuesday", from: new Date(), to: new Date(), isOn: true },
    { day: "Wednesday", from: new Date(), to: new Date(), isOn: true },
    { day: "Thursday", from: new Date(), to: new Date(), isOn: true },
    { day: "Friday", from: new Date(), to: new Date(), isOn: true },
    { day: "Saturday", from: new Date(), to: new Date(), isOn: true },
    { day: "Sunday", from: new Date(), to: new Date(), isOn: false },
  ]);

  const [countries, setCountries] = useState([]);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setMenuIsOpen(false); // Close the menu after selection
  };

  const handleInputChange = (inputValue, { action }) => {
    if (action === "input-change") {
      setMenuIsOpen(!!inputValue); // Open the menu only when there is input
    }
  };

  const handleTimePicker = (index, time, type) => {
    try {
      let shallowCopy = [...operationHours]
      if (type === 'from') {
        shallowCopy[index].from = time
      } else {
        shallowCopy[index].to = time
      }
      setOperationHours(shallowCopy)
    } catch (error) {
      console.log("🚀 ~ file: onboardtwo.js:57 ~ handleTimePicker ~ error:", error)
    }
  };

  const handleSwitchChange = (index, checked) => {
    try {
      let shallowCopy = [...operationHours]
      shallowCopy[index].isOn = !checked
      setOperationHours(shallowCopy)
    } catch (error) {
      console.log("🚀 ~ file: onboardtwo.js:57 ~ handleTimePicker ~ error:", error)
    }
  };

  const DropdownIndicator = () => null;

  useEffect(() => {
    const stepper = new Stepper(document.querySelector("#demo"), {
      linear: false,
      animation: false,
    });
    // Open the first step by default
    stepper.to(1);
  }, []);

  const handleNext = () => {
    if (dentalRef.current) {
      dentalRef.current.click();
    }
  };

  const handleNext2 = () => {
    if (dentalRef2.current) {
      dentalRef2.current.click();
    }
  };

  const getAccountSetup = async () => {
    try {
      const { result } = await ProfileServices.getAccountSetup();
      const data = JSON.parse(result?.questionAnswerInfo)
      setSliderValue(parseInt(data[0]?.answer))
      setValue('officeWebsite', data[1]?.answer)
      if (data[2]?.answer) {
        setOperationHours(JSON.parse(data[2]?.answer))
      }
      const colorOpt = data[3]?.answer.split(',')
      setSelectedOption(Insurance.filter(e => colorOpt.includes(e.value)))
      const payOpt = data[4]?.answer.split(',')
      payOpt.forEach(e => {
        setValue(`payment-${e}`, true)
      })
      const specialtyOpt = data[5]?.answer.split(',')
      specialtyOpt.forEach(e => {
        setValue(`specialty-${e}`, true)
      })
      const proceduresOpt = data[6]?.answer.split(',')
      proceduresOpt.forEach(e => {
        setValue(`procedures-${e}`, true)
      })
      setSlider2Value(parseInt(data[7]?.answer))
      setValue('affordableDentist', data[8]?.answer)
      setValue('idealPatient', data[9]?.answer)
      setValue('equipment', data[10]?.answer)
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const accountSetup = async (formData) => {
    setLoading(true)
    try {
      const colors =
        selectedOption?.length > 0
          ? selectedOption.map((item) => item.value)
          : [];
      const payments = [];
      const specialty = [];
      const procedures = [];

      for (let key in formData) {
        if (key.includes("payment")) {
          if (formData[key] === true) {
            payments.push(key.replace("payment-", ""));
          }
        }
        if (key.includes("specialty")) {
          if (formData[key] === true) {
            specialty.push(key.replace("specialty-", ""));
          }
        }
        if (key.includes("procedures")) {
          if (formData[key] === true) {
            procedures.push(key.replace("procedures-", ""));
          }
        }
      }
      if (formData?.otherPayment) {
        payments.push(formData?.otherPayment);
      }

      let questionAnswerInfo = [
        {
          question: "How Many Doctors Are in Your Practice?",
          answer: sliderValue,
          type: "slider",
        },
        {
          question: "What Is Your Office Website",
          answer: formData?.officeWebsite,
          type: "text",
        },
        {
          question: "What Are Your Hours of Operation",
          answer: JSON.stringify(operationHours),
          type: "select",
        },
        {
          question: "What Dental Insurance Plans Do You Take?",
          answer: colors.join(","),
          type: "select",
        },
        {
          question: "What Payment Methods Are Accepted?",
          answer: payments.join(","),
          type: "checkbox",
        },
        {
          question: "What Is Your Specialty",
          answer: specialty.join(","),
          type: "checkbox",
        },
        {
          question: "What Procedures Do You Prefer To Do?",
          answer: procedures.join(","),
          type: "checkbox",
        },
        {
          question: "On A Scale Of 1-5, With 1 Being The Lowest And 5 Being The Highest, Where Would You Rank Your Office Pricing",
          answer: slider2Value,
          type: "slider",
        },
        {
          question: "Do You Market Yourself As An Affordable Dentist?",
          answer: formData?.affordableDentist,
          type: "radio",
        },
        {
          question: "What Does Your Ideal Patient Look Like?",
          answer: formData?.idealPatient,
          type: "radio",
        },
        {
          question: "What Equipment Do You Have?",
          answer: formData?.equipment,
          type: "text",
        },
      ];
      let obj = {
        questionAnswerInfo: questionAnswerInfo,
        businessInfo: {
          businessName: formData?.businessName,
          officeContact: formData?.officePhone,
          mobile: formData?.phone,
          zipCode: formData?.zipCode,
          address: formData?.address,
          city: formData?.city,
          // country: formData?.country,
          state: formData?.state
        }
      };
      const { message } = await ProfileServices.accountSetup(obj);
      SuccessToaster(message);
      navigate("/dashboard");
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false)
    }
  };

  const getProfileDetail = async () => {
    try {
      const { data } = await ProfileServices.getProfileDetails()
      setValue('businessName', data?.businessName)
      setValue('officePhone', data?.officeContact)
      setValue('phone', data?.mobile)
      setValue('address', data?.address)
      // setValue('country', data?.country)
      setValue('state', data?.state)
      setValue('city', data?.city)
      setValue('zipCode', data?.zipCode)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  const getCountries = async () => {
    try {
      const { data } = await CountryServices.getCountries()
      setCountries(data)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  useEffect(() => {
    getAccountSetup()
    getProfileDetail()
    getCountries()
  }, [])

  return (
    <Wrapper>
      <div className="layout-page">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <h2 className="settings-header mt-6">
              <span className="text-muted">Settings&nbsp;/&nbsp;</span>
              <span>Account Setup</span>
            </h2>
            <div
              className="col-12 mb-4 card"
              style={{ background: "#fff", borderRadius: "10px" }}
            >
              <div className="bs-stepper wizard-numbered mt-2">
                <div id="demo" className="bs-stepper">
                  <div className="bs-stepper-header">
                    <div className="step" data-target="#test-l-1">
                      <button
                        className="step-trigger"
                        role="tab"
                        id="stepper1trigger1"
                      >
                        <span className="bs-stepper-circle">1</span>
                        <span className="bs-stepper-label">
                          <span className="bs-stepper-title">
                            Office Details
                          </span>
                          <span className="bs-stepper-subtitle">
                            About your Practice
                          </span>
                        </span>
                      </button>
                    </div>
                    <div className="line">
                      <IconChevronRight />
                    </div>
                    <div className="step" data-target="#test-l-2">
                      <button
                        ref={dentalRef}
                        className="step-trigger"
                        role="tab"
                        id="stepper1trigger2"
                      >
                        <span className="bs-stepper-circle">2</span>
                        <span className="bs-stepper-label">
                          <span className="bs-stepper-title">
                            Dental Service
                          </span>
                          <span className="bs-stepper-subtitle">
                            What you do
                          </span>
                        </span>
                      </button>
                    </div>
                    <div className="line">
                      <IconChevronRight />
                    </div>
                    <div className="step" data-target="#test-l-3">
                      <button
                        type="button"
                        className="step-trigger"
                        ref={dentalRef2}
                        role="tab"
                        id="stepper1trigger3"
                      >
                        <span className="bs-stepper-circle">
                          <IconUser />
                        </span>
                        <span className="bs-stepper-label">
                          <span className="bs-stepper-title">Business</span>
                          <span className="bs-stepper-subtitle">
                            Enter Information
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="bs-stepper-content">
                    <Form>
                      <div
                        id="test-l-1"
                        role="tabpanel"
                        className="content"
                        aria-labelledby="stepper1trigger1"
                      >
                        <div className="content-header mb-3">
                          {/* <h6 className="mb-0">Account Details</h6>
                              <small>Enter Your Account Details.</small> */}
                        </div>
                        <div className="row g-3">
                          <div className=" col-xl-6 col-xs-12 col-lg-8 col-md-12 col-sm-12">
                            <h4>How Many Doctors Are in Your Practice? </h4>
                            <CustomSlider
                              className="py-5"
                              start={sliderValue}
                              onChange={(v) => setSliderValue(v)}
                            />
                            <br />
                            <br />
                            <Form.Group>
                              <h4>What Is Your Office Website</h4>
                              <Form.Control
                                type="text"
                                placeholder="www.abcdental.com"
                                {...register("officeWebsite")}
                                className="box-sh-hide"
                              />
                            </Form.Group>
                            <br />

                            <h4>What Are Your Hours of Operation</h4>
                            {operationHours.map((item, index) => (
                              <Row className="mb-3" key={index}>
                                <Col
                                  xs="12"
                                  sm="3"
                                  className="d-flex align-items-center"
                                >
                                  <label className="switch switch-primary ms-3 ms-sm-0 mt-2">
                                    <input
                                      type="checkbox"
                                      className="switch-input price-duration-toggler"
                                      id={`switch-${item?.day}`}
                                      checked={item?.isOn}
                                      onChange={(e) => handleSwitchChange(index, item?.isOn)}
                                    />
                                    <span className="switch-toggle-slider">
                                      <span className="switch-on"></span>
                                      <span className="switch-off"></span>
                                    </span>
                                    <span
                                      className={`switch-label ${item?.isOn && "text-primary"
                                        }`}
                                    >
                                      {item?.day}
                                    </span>
                                  </label>
                                </Col>
                                <Col xs="5" sm="3">
                                  <TimersPick
                                    disabled={!item?.isOn}
                                    options={{
                                      noCalendar: true,
                                      enableTime: true,
                                      dateFormat: "h:i K",
                                      time_24hr: false,
                                    }}
                                    className="custom-timepicker"
                                    value={item?.from}
                                    onChange={(time) => handleTimePicker(index, time, 'from')}
                                  />
                                </Col>
                                <Col xs="1" className="mt-2 text-center">
                                  <span className="mt-2">To</span>
                                </Col>
                                <Col xs="5" sm="3">
                                  <TimersPick
                                    disabled={!item?.isOn}
                                    options={{
                                      noCalendar: true,
                                      enableTime: true,
                                      dateFormat: "h:i K",
                                      time_24hr: false,
                                    }}
                                    className="custom-timepicker"
                                    value={item?.to}
                                    onChange={(time) => handleTimePicker(index, time, 'to')}
                                  />
                                </Col>
                              </Row>
                            ))}

                            <h4>What Dental Insurance Plans Do You Take?</h4>
                            <Select
                              components={{ DropdownIndicator }}
                              isMulti
                              id="TypeaheadPrefetch"
                              className="form-control typeahead-prefetch"
                              value={selectedOption}
                              onChange={handleChange}
                              onInputChange={handleInputChange}
                              options={Insurance}
                              placeholder="Enter a Plan"
                              menuIsOpen={menuIsOpen}
                            // autoComplete="off"
                            />
                            <br />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-10">
                            <h4>What Payment Methods Are Accepted?</h4>
                            <Row>
                              <Col>
                                <Form.Check
                                  custom
                                  type="checkbox"
                                  id="care-credit"
                                  label="Care Credit"
                                  className="mt-2 box-sh-hide"
                                  {...register("payment-care-credit")}
                                />
                                <Form.Check
                                  custom
                                  type="checkbox"
                                  id="visa"
                                  label="Visa"
                                  className="mt-3"
                                  {...register("payment-visa")}
                                />
                              </Col>
                              <Col>
                                <Form.Check
                                  custom
                                  type="checkbox"
                                  id="mastercard"
                                  label="Mastercard"
                                  className="mt-2"
                                  {...register("payment-master-card")}
                                />
                                <Form.Check
                                  custom
                                  type="checkbox"
                                  id="cash"
                                  label="Cash"
                                  className="mt-3"
                                  {...register("payment-cash")}
                                />
                              </Col>
                              <Col>
                                <Form.Check
                                  custom
                                  type="checkbox"
                                  id="american-express"
                                  label="American Express"
                                  className="mt-2"
                                  {...register("payment-american-express")}
                                />
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col xs={5}>
                                <Form.Control
                                  placeholder="Other"
                                  {...register("otherPayment")}
                                  className="box-sh-hide"
                                />
                              </Col>
                            </Row>
                          </div>
                          <div className="col-12">
                            <div className="mt-2 text-end">
                              <button
                                onClick={handleNext}
                                type="button"
                                className=" btn btn-warning"
                              >
                                Save & Next <IconArrowRight />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="test-l-2"
                        role="tabpanel"
                        className="content"
                        aria-labelledby="stepper1trigger2"
                      >
                        <div className="content-header mb-3">
                          {/* <h6 className="mb-0">Personal Info</h6>
                              <small>Enter Your Personal Info.</small> */}
                        </div>
                        <div className="row g-3">
                          <div className="col-xl-7 col-xs-12 col-lg-8 col-md-12 col-sm-12">
                            <h4>
                              What Is Your Specialty ?
                              <sup
                                style={{
                                  width: "100%",
                                  color: "#61d9ca",
                                  verticalAlign: "sub",
                                }}
                              >
                                <i> if any</i>
                              </sup>
                            </h4>
                            <Row className="mb-3">
                              <Col xl={4} lg={8} xs={12} md={12}>
                                <Form.Check
                                  label="Cosmetic"
                                  {...register("specialty-Cosmetic")}
                                />
                              </Col>
                              <Col xl={4} lg={8} xs={12} md={12}>
                                <Form.Check
                                  label="Endodontic"
                                  {...register("specialty-Endodontic")}
                                />
                              </Col>
                              <Col xl={4} lg={8} xs={12} md={12}>
                                <Form.Check
                                  label="Oral and Maxillofacial Surgery"
                                  {...register(
                                    "specialty-Oral and Maxillofacial Surgery"
                                  )}
                                />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col xl={4} lg={8} xs={12} md={12}>
                                <Form.Check
                                  label="Orthodontic"
                                  {...register("specialty-Orthodontic")}
                                />
                              </Col>
                              <Col xl={4} lg={8} xs={12} md={12}>
                                <Form.Check
                                  label="Pediatric"
                                  {...register("specialty-Pediatric")}
                                />
                              </Col>
                              <Col xl={4} lg={8} xs={12} md={12}>
                                <Form.Check
                                  label="Periodontic"
                                  {...register("specialty-Periodontic")}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={4} lg={8} xs={12} md={12}>
                                <Form.Check
                                  label="Prosthodontic"
                                  {...register("specialty-Prosthodontic")}
                                />
                              </Col>
                              <Col xl={4} lg={8} xs={12} md={12}>
                                <Form.Check
                                  label="General Practitioner"
                                  {...register(
                                    "specialty-General Practitioner"
                                  )}
                                />
                              </Col>
                            </Row>

                            <h4 className="mt-4">
                              What Procedures Do You Prefer To Do?
                            </h4>
                            <Row className="mb-3">
                              <Col xs={6} md={4}>
                                <Form.Check
                                  label="Fillings"
                                  {...register("procedures-Fillings")}
                                />
                              </Col>
                              <Col xs={6} md={4}>
                                <Form.Check
                                  label="Hygiene Checks/Maintenance"
                                  {...register(
                                    "procedures-Hygiene Checks/Maintenance"
                                  )}
                                />
                              </Col>
                              <Col xs={6} md={4}>
                                <Form.Check
                                  label="Crown"
                                  {...register("procedures-Crown")}
                                />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col xs={6} md={4}>
                                <Form.Check
                                  label="Bridge"
                                  {...register("procedures-Bridge")}
                                />
                              </Col>
                              <Col xs={6} md={4}>
                                <Form.Check
                                  label="Full Smile Restoration"
                                  {...register(
                                    "procedures-Full Smile Restoration"
                                  )}
                                />
                              </Col>
                              <Col xs={6} md={4}>
                                <Form.Check
                                  label="Implant"
                                  {...register("procedures-Implant")}
                                />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col xs={6} md={4}>
                                <Form.Check
                                  label="Braces"
                                  {...register("procedures-Braces")}
                                />
                              </Col>
                              <Col xs={6} md={4}>
                                <Form.Check
                                  label="Cleaner Aligner"
                                  {...register("procedures-Cleaner Aligner")}
                                />
                              </Col>
                              <Col xs={6} md={4}>
                                <Form.Check
                                  label="Root Canal"
                                  {...register("procedures-Root Canal")}
                                />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col xs={6} md={4}>
                                <Form.Check
                                  label="Extractions"
                                  {...register("procedures-Extractions")}
                                />
                              </Col>
                              <Col xs={6} md={4}>
                                <Form.Check
                                  label="Dentures"
                                  {...register("procedures-Dentures")}
                                />
                              </Col>
                              <Col xs={6} md={4}>
                                <Form.Check
                                  label="Pediatric Dentistry"
                                  {...register(
                                    "procedures-Pediatric Dentistry"
                                  )}
                                />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col xs={6} md={4}>
                                <Form.Check
                                  label="TMJ/TMD"
                                  {...register("procedures-TMJ/TMD")}
                                />
                              </Col>
                              <Col xs={6} md={4}>
                                <Form.Check
                                  label="Emergency Dental Care"
                                  {...register(
                                    "procedures-Emergency Dental Care"
                                  )}
                                />
                              </Col>
                            </Row>

                            <br />
                            <h4 className="mt-4">
                              On A Scale Of 1-5, With 1 Being The Lowest And 5
                              Being The Highest, Where Would You Rank Your
                              Office Pricing
                            </h4>
                            <div style={{ width: "100%", margin: "20px auto" }}>
                              <CustomSlidermin
                                start={slider2Value}
                                onChange={(v) => setSlider2Value(v)}
                              />
                            </div>
                            <Form.Group as={Row} className="mb-3">
                              <h4 className="mt-4">
                                Do You Market Yourself As An Affordable Dentist?
                              </h4>
                              <Col sm={12}>
                                <Form.Check
                                  inline
                                  type="radio"
                                  label="Yes"
                                  value="Yes"
                                  name="affordableDentist"
                                  id="affordableDentistYes"
                                  {...register("affordableDentist")}
                                />
                                <Form.Check
                                  inline
                                  type="radio"
                                  label="No"
                                  value="No"
                                  name="affordableDentist"
                                  id="affordableDentistNo"
                                  {...register("affordableDentist")}
                                />
                              </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3 ">
                              <h4 className="mt-4">
                                What Does Your Ideal Patient Look Like?
                              </h4>
                              <Col sm={12}>
                                <Form.Check
                                  inline
                                  type="radio"
                                  label="Medicaid families with multiple kids"
                                  value="Medicaid families with multiple kids"
                                  name="idealPatient"
                                  {...register('idealPatient')}
                                />
                                <Form.Check
                                  inline
                                  type="radio"
                                  label="High reimbursing insurance patient who needs routine checks and occasional fillings, cash paying full mouth restoration"
                                  value="High reimbursing insurance patient who needs routine checks and occasional fillings, cash paying full mouth restoration"
                                  name="idealPatient"
                                  {...register('idealPatient')}
                                />
                                <Form.Check
                                  inline
                                  type="radio"
                                  label="cash paying full mouth restoration"
                                  value="cash paying full mouth restoration"
                                  name="idealPatient"
                                  {...register('idealPatient')}
                                />
                              </Col>
                            </Form.Group>

                            {/* Third Question */}
                            <Form.Group as={Row} className="mb-3">
                              <h4 className="mt-4">
                                What Equipment Do You Have?{" "}
                                <sup>
                                  {" "}
                                  <i
                                    style={{
                                      width: "100%",
                                      color: "#61d9ca",
                                      verticalAlign: "sub",
                                    }}
                                  >
                                    please indicate brands and models
                                  </i>
                                </sup>
                              </h4>
                              <Col sm={12}>
                                <Form.Control
                                  as="textarea"
                                  placeholder="CBCT/Pan-X/Ray's/scanner/model, etc."
                                  {...register("equipment")}
                                />
                              </Col>
                            </Form.Group>
                          </div>

                          <div className="col-12">
                            <div className="mt-2 text-end">
                              <button
                                onClick={handleNext2}
                                type="button"
                                className=" btn btn-warning"
                              >
                                Save & Next <IconArrowRight />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ---- 3rd tab Buisness Information--- */}
                      <div
                        id="test-l-3"
                        className="content"
                        role="tabpanel"
                        aria-labelledby="stepper1trigger3"
                      >
                        <div className="content-header mb-4">
                          <h3 className="mb-1">Business Information</h3>
                          <p>Enter Your Business Information</p>
                        </div>
                        <div className="row g-3">
                          <div className="col-sm-6">
                            <label
                              className="form-label"
                              for="multiStepsFirstName"
                            >
                              Business Name
                            </label>
                            <input
                              type="text"
                              id="multiStepsFirstName"
                              name="multiStepsFirstName"
                              className="form-control"
                              placeholder="business"
                              {...register("businessName", {
                                required: "Please enter business name",
                              })}
                            />
                            {error && (
                              <p className={"error"}>
                                {error?.businessName?.message}
                              </p>
                            )}
                          </div>
                          <div className="col-sm-6">
                            <label
                              className="form-label"
                              for="multiStepsLastName"
                            >
                              Office Contact
                            </label>
                            <input
                              type="text"
                              id="multiStepsLastName"
                              name="multiStepsLastName"
                              className="form-control"
                              placeholder="202 555 0111"
                              {...register("officePhone")}
                            />
                          </div>
                          <div className="col-sm-6">
                            <label
                              className="form-label"
                              for="multiStepsMobile"
                            >
                              Mobile
                            </label>
                            <div className="input-group">
                              {/* <span className="input-group-text">US (+1)</span> */}
                              <input
                                type="text"
                                id="multiStepsMobile"
                                name="multiStepsMobile"
                                className="form-control multi-steps-mobile"
                                placeholder="202 555 0111"
                                {...register("phone")}
                              />
                            </div>
                          </div>
                          {/* <div className="col-sm-6">
                            <label className="form-label" for="multiStepsState">
                              Country
                            </label>
                            <select
                              id="multiStepsState"
                              className="select2 form-select"
                              data-allow-clear="true"
                              {...register("country")}
                            >
                              <option value=""> Select Country </option>
                              {countries?.map((item, index) => (
                                <option key={index} value={item?.name}>{item?.name}</option>
                              ))}
                            </select>
                          </div> */}
                          <div className="col-sm-6">
                            <label
                              className="form-label"
                              for="multiStepsPincode"
                            >
                              State
                            </label>
                            <input
                              type="text"
                              id="multiStepsPincode"
                              name="multiStepsPincode"
                              className="form-control multi-steps-pincode"
                              placeholder="State"
                              {...register("state")}
                            />
                          </div>
                          <div className="col-sm-6">
                            <label className="form-label" for="multiStepsCity">
                              City
                            </label>
                            <input
                              type="text"
                              id="multiStepsCity"
                              className="form-control"
                              placeholder="City"
                              {...register("city")}
                            />
                          </div>
                          <div className="col-sm-6">
                            <label
                              className="form-label"
                              for="multiStepsPincode"
                            >
                              Zip/Postal Code
                            </label>
                            <input
                              type="text"
                              id="multiStepsPincode"
                              name="multiStepsPincode"
                              className="form-control multi-steps-pincode"
                              placeholder="Postal Code"
                              maxlength="6"
                              {...register("zipCode")}
                            />
                          </div>
                          <div className="col-md-12">
                            <label
                              className="form-label"
                              for="multiStepsAddress"
                            >
                              Address
                            </label>
                            <input
                              type="text"
                              id="multiStepsAddress"
                              name="multiStepsAddress"
                              className="form-control"
                              placeholder="Address"
                              {...register("address")}
                            />
                          </div>

                          <div className=" mt-5 text-end">
                            <Link to="/onboard-thanks">
                              <button
                                disabled={loading}
                                onClick={handleSubmit(accountSetup)}
                                type="button"
                                className="btn btn-warning w-25"
                              >
                                Finish
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="content-backdrop fade"></div>
        </div>
      </div>
    </Wrapper>
  );
}
