import React, { useEffect, useState } from "react";
import "../assets/vendor/css/pages/front-page.css";
import "../assets/vendor/css/rtl/core.css";
import "../assets/vendor/css/rtl/theme-default.css";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/UseContext";
import { ErrorToaster } from "./Toaster";
import SubscriptionServices from "../apis/subscription/SubscriptionServices";
import Spinner from "./spinner";
import ProfileServices from "../apis/profile/ProfileServices";

export default function Pricingpckg() {

  const { user } = useAuth();
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);

  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const [plans, setPlans] = useState([]);
  const [currentPlan, setCurrentPlan] = useState();

  const getSubscriptionPlans = async () => {
    setLoader(true)
    try {
      const { data } = await SubscriptionServices.getSubscriptionPlans()
      setPlans(data)
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoader(false)
    }
  }

  const getCurrentPlan = async () => {
    try {
      const { data } = await ProfileServices.getCurrentPlan()
      setCurrentPlan(data?.subscriptionDetails)
    } catch (error) {
      ErrorToaster(error)
    }
  }
  const getCardDetail = async () => {
    const { portal_session } = await SubscriptionServices.getCardDetail();
    window.location.href=portal_session.url
  };

  useEffect(() => {
    // getSubscriptionPlans()
    // getCurrentPlan()
    getCardDetail()
  }, []);

  return (
    <>
      <h2 className="text-center mb-2 fw-bold">Redirecting to Stripe</h2>
      <p className="text-center">
        You can Manage Subscription, Cancellation, Cards on Stripe Customer Portal
      </p>
      <Spinner size='large' />
    </>
  );
}
