import { get, post, deleted } from '../index';
import PageRoutes from './Page.Routes';

const PageServices = {
  getPages: async (params) => {
    const data = await get(PageRoutes.getPages, params);
    return data;
  },
  getMetaData: async (params) => {
    const data = await get(PageRoutes.getMetaData, params);
    return data;
  },
  pageReindex: async (obj) => {
    const data = await post(PageRoutes.pageReindex, obj);
    return data;
  },
  deletePage: async (params) => {
    const data = await deleted(PageRoutes.deletePage, params);
    return data;
  },
  getPageMetaData: async (params) => {
    const data = await get(PageRoutes.getPageMetaData, params);
    return data;
  },
}

export default PageServices