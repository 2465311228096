import React from 'react';
import "../assets/css/slider.css"

function CustomSlidermin({
  min = 0,
  max = 5,
  start,
  onChange = () => { },
  step = 1,
}) {

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const renderRuler = () => {
    let rulerMarks = [];
    for (let i = min; i <= max; i += step) {
      rulerMarks.push(
        <div
          className="ruler-mark"
          key={i}
          style={{ left: `${(i / max) * 100}%` }}
        >
          <div className="ruler-label">{i === max ? `${i}+` : i}</div>
        </div>
      );
    }
    return <div className="ruler">{rulerMarks}</div>;
  };

  return (
    <div className="slider-container">
      <input
        type="range"
        min={min}
        max={max + step}
        value={start}
        onChange={handleChange}
        className="slider"
        step={step}
      />
      {renderRuler()}
    </div>
  );
}

export default CustomSlidermin;