import React, { useState, useEffect } from "react";
import "../assets/vendor/css/rtl/core.css";
import "../assets/vendor/css/rtl/theme-default.css";
import AccountPanel from "../components/accountpanel";
import {
  IconUserCheck,
  IconLock,
  IconCurrencyDollar,
} from "@tabler/icons-react";
import DATA from "../assets/json/projects-list.json";
import LOG from "../assets/json/invoice-list.json";
import pdf from "../assets/img/icons/misc/pdf.png";
import av3 from "../assets/img/avatars/3.png";
import av5 from "../assets/img/avatars/5.png";
import av9 from "../assets/img/avatars/9.png";
import av4 from "../assets/img/avatars/4.png";
import DataTable from "react-data-table-component";
import { Button, Tabs, Tab } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import NewSidebar from "../components/newsidebar";
import Wrapper from "../wrapper/wrapper";
export default function Personal({ props }) {
  const [data, setData] = useState([]);
  const [log, setLog] = useState([]);
  const [search, setSearch] = useState("");
  const [key, setKey] = useState("tab1"); // Default to the first tab

  const columns = [
    {
      name: "Project Name",
      cell: (row) => (
        <div class="d-flex justify-content-left align-items-center">
          <div class="avatar-wrapper">
            <div class="avatar avatar-sm me-3">
              <img
                className="rounded-circle"
                src={row.project_image}
                alt={row.project_name}
                style={{ width: "30px", height: "30px" }}
              />
            </div>
          </div>
          <div class="d-flex flex-column">
            <span class="text-truncate fw-medium"> {row.project_name}</span>
            <small class="text-muted">{row.framework}</small>
          </div>
        </div>
      ),
    },
    {
      name: "Total Task",
      selector: "total_task",
    },
    {
      name: "Progress",
      cell: (row) => (
        <>
          <div className="d-flex flex-column w-100">
            <small className="mb-1">{row.progress}%</small>
            <div className="progress w-100 me-3" style={{ height: "6px" }}>
              <div
                className="progress-bar bg-success"
                style={{ width: `${row.progress}%` }}
                aria-valuenow={row.progress}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </>
      ),
    },
    {
      name: "hours",
      selector: "hours",
    },
  ];

  useEffect(() => {
    setData(DATA.data);
    setLog(LOG.data);
  }, []);

  return (
    <Wrapper>
      <div className="layout-page">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="py-3 mb-4">Personal</h4>
            <div className="row">
              <AccountPanel />
              <div className="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1">
                <ul className="nav nav-pills flex-column flex-md-row mb-4">
                  <li className="nav-item">
                    <NavLink to="/personal" className="nav-link active">
                      <IconUserCheck className="me-1" size={24} />
                      Account
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/security" className="nav-link">
                      <IconLock className="me-1" size={24} />
                      Security
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/billing" className="nav-link disable">
                      <IconCurrencyDollar className="me-1" size={24} />
                      Billing & Plans
                    </NavLink>
                  </li>
                </ul>

                <div class="card mb-4">
                  <h5 class="card-header">User's Projects List</h5>
                  <div class="table-responsive mb-3 table-container">
                    <DataTable
                      columns={columns}
                      data={data}
                      pagination
                      paginationPerPage={7}
                      responsive={true}
                      highlightOnHover
                      // striped
                      className="table user-data-table" // Apply Bootstrap's .table-striped class
                      subHeader
                      subHeaderComponent={
                        <>
                          <label>Search</label>
                          <input
                            type="text"
                            placeholder="Search Project"
                            className="w-25 form-control"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </>
                      }
                    />
                  </div>
                </div>

                <div class="card mb-4">
                  <h5 class="card-header">User Activity Timeline</h5>
                  <div class="card-body pb-0">
                    <ul class="timeline mb-0">
                      <li class="timeline-item timeline-item-transparent">
                        <span class="timeline-point timeline-point-primary"></span>
                        <div class="timeline-event">
                          <div class="timeline-header mb-1">
                            <h6 class="mb-0">12 Invoices have been paid</h6>
                            <small class="text-muted">12 min ago</small>
                          </div>
                          <p class="mb-2">
                            Invoices have been paid to the company
                          </p>
                          <div class="d-flex">
                            <a href="javascript:void(0)" class="me-3">
                              <img
                                src={pdf}
                                alt="PDF image"
                                width="15"
                                class="me-2"
                              />
                              <span class="fw-medium text-heading">
                                invoices.pdf
                              </span>
                            </a>
                          </div>
                        </div>
                      </li>
                      <li class="timeline-item timeline-item-transparent">
                        <span class="timeline-point timeline-point-warning"></span>
                        <div class="timeline-event">
                          <div class="timeline-header mb-1">
                            <h6 class="mb-0">Client Meeting</h6>
                            <small class="text-muted">45 min ago</small>
                          </div>
                          <p class="mb-2">Project meeting with john @10:15am</p>
                          <div class="d-flex flex-wrap">
                            <div class="avatar me-3">
                              <img
                                src={av3}
                                alt="Avatar"
                                class="rounded-circle"
                              />
                            </div>
                            <div>
                              <h6 class="mb-0">Lester McCarthy (Client)</h6>
                              <small>CEO of Pixinvent</small>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="timeline-item timeline-item-transparent">
                        <span class="timeline-point timeline-point-info"></span>
                        <div class="timeline-event">
                          <div class="timeline-header mb-1">
                            <h6 class="mb-0">
                              Create a new project for client
                            </h6>
                            <small class="text-muted">2 Day Ago</small>
                          </div>
                          <p class="mb-2">5 team members in a project</p>
                          <div class="d-flex align-items-center avatar-group">
                            <div
                              class="avatar pull-up"
                              data-bs-toggle="tooltip"
                              data-popup="tooltip-custom"
                              data-bs-placement="top"
                              title="Vinnie Mostowy"
                            >
                              <img
                                src={av5}
                                alt="Avatar"
                                class="rounded-circle"
                              />
                            </div>

                            <div
                              class="avatar pull-up"
                              data-bs-toggle="tooltip"
                              data-popup="tooltip-custom"
                              data-bs-placement="top"
                              title="Jimmy Jackson"
                            >
                              <img
                                src={av9}
                                alt="Avatar"
                                class="rounded-circle"
                              />
                            </div>

                            <div
                              class="avatar pull-up"
                              data-bs-toggle="tooltip"
                              data-popup="tooltip-custom"
                              data-bs-placement="top"
                              title="Nelson Wilson"
                            >
                              <img
                                src={av4}
                                alt="Avatar"
                                class="rounded-circle"
                              />
                            </div>
                          </div>
                        </div>
                      </li>

                      <li class="timeline-item timeline-item-transparent border-transparent">
                        <span class="timeline-point timeline-point-success"></span>
                        <div class="timeline-event">
                          <div class="timeline-header mb-1">
                            <h6 class="mb-0">Design Review</h6>
                            <small class="text-muted">5 days Ago</small>
                          </div>
                          <p class="mb-0">
                            Weekly review of freshly prepared design for our new
                            app.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="card mb-4">
                  <div class="table-responsive mb-3 table-container">
                    <DataTable
                      // customStyles={}
                      columns={columns}
                      data={data}
                      pagination
                      paginationPerPage={7}
                      responsive={true}
                      highlightOnHover
                      striped
                      className="table table-striped user-data-table" // Apply Bootstrap's .table-striped class
                      // actions={
                      //   <button className="btn btn-danger">Pdf</button>
                      // }
                      subHeader
                      subHeaderComponent={<Button>Export</Button>}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="content-backdrop fade"></div>
        </div>
      </div>
    </Wrapper>
  );
}
