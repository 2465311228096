import {
  IconExternalLink,
  IconFileInvoice,
  IconListDetails,
  IconRefresh,
  IconSearch,
  IconTrash,
} from "@tabler/icons-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import PageServices from "../apis/pages/PageServices";
import "../assets/css/planspage.css";
import { ErrorToaster, SuccessToaster } from "../components/Toaster";
import Spinner from "../components/spinner";
import { useAuth } from "../context/UseContext";
import Wrapper from "../wrapper/wrapper";

export default function PageList() {
  const navigate = useNavigate();
  const [isSearchable, setIsSearchable] = useState(true);

  const [selectedRows, setSelectedRows] = useState([]);
  const [loader, setLoader] = useState(false);
  const { user } = useAuth();
  const [pages, setPages] = useState([]);
  const [searchedPages, setSearchedPages] = useState([]);

  const getPages = async () => {
    setLoader(true);
    try {
      let params = {
        projectId: user?.projectId,
      };
      const { data } = await PageServices.getPages(params);
      setPages(data);
      setSearchedPages(data);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  const searchPages = (value) => {
    if (value) {
      let val = value?.toLowerCase();
      const filteredData = pages.filter((e) =>
        e?.page_url?.toLowerCase().includes(val)
      );
      setSearchedPages(filteredData);
    } else {
      setSearchedPages(pages);
    }
  };

  const pageReindex = async (id) => {
    try {
      const obj = {
        projectId: user?.projectId,
        pageId: id,
      };
      const { data } = await PageServices.pageReindex(obj);
      SuccessToaster("Page reindex successfully");
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const deletePage = async (id) => {
    try {
      setPages([]);
      let params = {
        projectId: user?.projectId,
        pageId: id,
      };
      const { data } = await PageServices.deletePage(params);
      getPages();
      SuccessToaster("Page Delete successfully");
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const ActionButtons = (row) => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        paddingRight: "30px",
      }}
    >
      <IconRefresh
        size={20}
        style={{ cursor: "pointer" }}
        className="icon-style"
        onClick={() => pageReindex(row?.id)}
      />
      <IconTrash
        size={20}
        style={{ cursor: "pointer" }}
        className="icon-style"
        onClick={() => deletePage(row?.id)}
      />
      <IconFileInvoice
        size={20}
        className="icon-style"
        style={{ cursor: "pointer" }}
      />
      <IconListDetails
        size={20}
        className="icon-style"
        style={{ cursor: "pointer" }}
        onClick={() => navigate(`/metadata/${row?.id}`)}
      />
    </div>
  );

  useEffect(() => {
    return () => {
      document.body.classList.add("bioshop-body", "shopper-bioshop-body");

      //  componentWillUnmount.current = true;
      document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
    };
  }, []);

  const colourOptions = [
    { value: "", label: "Select action" },
    { value: "full", label: "Re-crawl" },
    { value: "modify", label: "Delete" },
  ];

  let crawlOpt = [
    { value: "", label: "All" },
    { value: "Ok", label: "Ok" },
    { value: "Failed", label: "Failed" },
    { value: "N/A", label: "N/A" },
    { value: "Queued", label: "Queued" },
    { value: "Limited", label: "Limited" },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "white" : "black", // Change text color on hover (focus)
      backgroundColor: state.isFocused
        ? "#61d9ca" // Background color on hover (focus)
        : state.isSelected
        ? "#61d9ca" // Background color when option is selected
        : null,
    }),
  };

  const IndStatus = [
    { value: "", label: "All" },
    { value: "Ok", label: "Ok" },
    { value: "Failed", label: "Failed" },
    { value: "N/A", label: "N/A" },
    { value: "Queued", label: "Queued" },
    { value: "Limited", label: "Limited" },
  ];

  const handleChange = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const UrlCell = (row) => (
    <>
      <div
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title={row?.page_url}
      >
        <span>
          <a
            style={{ fontSize: ".9375rem", color: "#000" }}
            href={row?.page_url}
          >
            {row.page_url}{" "}
          </a>
        </span>{" "}
      </div>
    </>
  );

  const UrlCelltwo = (row) => (
    <>
      <div>
        <span style={{ fontSize: ".9375rem" }}>
          {moment(row?.updated_at).format("DD MMM YYYY")}
        </span>
      </div>
    </>
  );
  const CrawlStatus = (row) => (
    <>
      <div className="badge bg-primary ">
        <a href="" className="text-white " style={{ fontSize: ".9375rem" }}>
          {row?.crawl_status}{" "}
        </a>
      </div>
    </>
  );
  const FileName = (row) => (
    <>
      <div className="text-black ">
          {row?.filename}
      </div>
    </>
  );

  const IndexStatus = (row) => (
    <>
      <div className="badge bg-primary ">
        <a href="" className="text-white " style={{ fontSize: ".9375rem" }}>
          {row?.index_status}{" "}
        </a>
      </div>
    </>
  );

  const IconCol = (row) => (
    <>
      <IconExternalLink size={20} />
    </>
  );

  const columns = [
    // {
    //   name: "Url",
    //   width: "400px",
    //   cell: UrlCell,
    // },
    // {
    //   name: "",
    //   cell: IconCol,
    // },
    {
      name: 'File',
      cell: FileName,
    },
    {
      name: "Crawl Status",
      cell: CrawlStatus,
    },
    {
      name: "Index Status",
      cell: IndexStatus,
    },
    {
      name: "Last Updated",
      cell: UrlCelltwo,
    },
    // {
    //   name: "Words",
    //   cell: UrlCellwords,
    // },
    {
      name: "Actions",
      cell: ActionButtons,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  useEffect(() => {
    if (user?.configuredByAdmin) {
      getPages();
    }
  }, []);

  return (
    <div>
      <Wrapper>
        <div className="layout-page">
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <h2 className="settings-header mt-6">
                <span className="text-muted">Pages</span>
                {/* <span>Test name</span> */}
              </h2>
              {user?.configuredByAdmin ? (
                <>
                  <div class="row">
                    <div class="col-6 col-lg-3 col-xxl-3 mb-4">
                      <Select
                        className="basic-single no-focus"
                        classNamePrefix="select"
                        defaultValue={colourOptions[0]}
                        isSearchable={isSearchable}
                        name="color"
                        options={colourOptions}
                        styles={customStyles}
                      />
                    </div>
                    <div class="col-6 col-lg-3 col-xxl-3 mb-4">
                      <div class="input-group input-group-merge">
                        <span
                          class="input-group-text"
                          id="basic-addon-search31"
                        >
                          <IconSearch />
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search..."
                          aria-label="Search..."
                          aria-describedby="basic-addon-search31"
                          onChange={(e) => searchPages(e.target.value)}
                        />
                      </div>
                    </div>

                    <div class="col-6 col-lg-2 col-xxl-2 mb-4 offset-xxl-2">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={crawlOpt[0]}
                        isSearchable={isSearchable}
                        name="color"
                        options={crawlOpt}
                        styles={customStyles}
                      />
                    </div>
                    <div class="col-6 col-lg-2 col-xxl-2 mb-4">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={IndStatus[0]}
                        isSearchable={isSearchable}
                        name="color"
                        options={IndStatus}
                        styles={customStyles}
                      />
                    </div>
                  </div>

                  <div className="response-table">
                    {loader ? (
                      <Spinner size="large" />
                    ) : (
                      <DataTable
                        columns={columns}
                        data={searchedPages}
                        pagination
                        paginationPerPage={15}
                        responsive={true}
                        overflowY={true}
                        highlightOnHover
                        selectableRows
                        onSelectedRowsChange={handleChange}
                        className="table user-data-table style2 check_box_large"
                      />
                    )}
                  </div>
                </>
              ) : (
                <h5 className="py-3 mb-4 fw-bold">
                  Sorry, Chatbot is currently InActive.
                </h5>
              )}
            </div>

            <div class="content-backdrop fade"></div>
          </div>
        </div>
      </Wrapper>
    </div>
  );
}
