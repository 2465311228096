import { post } from '../index';
import AuthRoutes from './Auth.Routes';

const AuthServices = {
  login: async (obj) => {
    const data = await post(AuthRoutes.login, obj);
    return data;
  },
  register: async (obj) => {
    const data = await post(AuthRoutes.register, obj);
    return data;
  },
  forgotPassword: async (obj) => {
    const data = await post(AuthRoutes.forgotPassword, obj);
    return data;
  },
  updateProfile: async (obj) => {
    const data = await post(AuthRoutes.updateProfile, obj);
    return data;
  },
  checkCoupon: async (obj)=>{
    const data = await post(AuthRoutes.checkCoupon, obj);
    return data;
  },
  checkExists: async (obj) => {
    const data = await post(AuthRoutes.checkExists, obj);
    return data;
  }
}

export default AuthServices