import React, { useState } from "react";
import "../assets/vendor/css/rtl/core.css";
import "../assets/css/login.scss";
import "../assets/vendor/css/rtl/theme-default.css";
import NewSidebar from "../components/newsidebar";
import Wrapper from "../wrapper/wrapper";
import { Link } from "react-router-dom";
export default function Onboardhome() {
  return (
    <Wrapper>
      <div className="layout-page">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="card card-action mb-5 mt-3">
              <div className="jumbotron rounded">
                <div className="container text-center setup-style">
                  <p className="lead">
                    "Thank you for taking the time to provide us with these
                    valuable insights. Your input is crucial in ensuring that
                    Kriss.AI effectively represents your practice. We'll review
                    your responses during the onboarding process, making sure
                    everything is tailored just the way you want it.
                  </p>

                  <p className="lead">
                    Once again, welcome to Kriss.AI! We're here to enhance your
                    practice and support your patients in the best possible
                    way."
                  </p>
                  <Link to='/dashboard'>
                  <button className="setup mt-5 mb-3 text-center">
                    Go to the Main Page{" "}
                  </button>
                  </Link>
                
                </div>
              </div>
            </div>
          </div>

          <div className="content-backdrop fade"></div>
        </div>
      </div>
    </Wrapper>
  );
}
